import React, { useContext } from "react";
import Context from "context/UserContext";
import { useForm } from "react-hook-form";

import {
  Container,
  Form,
  Row,
  Col,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import { editTransfer } from "services/deposit";
import Swal from "sweetalert2";

export default function EstatusForm({ show, get_deposit, transfer }) {
  const { token } = useContext(Context);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();


  const [selectStatus, setSelectStatus] = React.useState(transfer.status);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (value.status === "Depositado") {
        setSelectStatus(value.status);
      }
      if (value.status === "Rechazado") {
        setSelectStatus(value.status );
      }
      if (value.status === "Solicitado") {
        setSelectStatus(value.status);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = ({ status, pic, descripcion }) => {
    const formData = new FormData();
    if (status === "Rechazado"){
      formData.append("descripcion", descripcion);
    }else if (pic !== undefined) {
      formData.append("imagen", pic[0]);
    }
    formData.append("status", status);
    formData.append("user_id", transfer.user_id);

    editTransfer(token, transfer.id, formData).then(({ data }) => {
      get_deposit();

      Swal.fire({
        icon: "success",
        title: "Éxito",
        confirmButtonColor: "#3b82f6",
        text: "Se ha actualizado el estatus exitosamente.",
      });
      show(false);
    });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Row>
          <Col>
            <p>Nombre: {transfer.user_nombre}</p>
            <p>Monto: ${transfer.monto} MXN</p>
            <p>Fecha: {transfer.fecha}</p>
            <p>Banco: {transfer.bank}</p>
            <p>Número de cuenta: {transfer.card_number}</p>
            <p>CLABE interbancaria: {transfer.interbank_key}</p>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="puntos">
              <Form.Label>Estatus</Form.Label>
              <Form.Select
                defaultValue={transfer.status}
                aria-label="selecciona estatus"
                {...register("status", {
                  required: "El campo es obligatorio.",
                })}
              >
                <option value="Solicitado">Solicitado</option>
                <option value="Depositado">Depositado</option>
                <option value="Rechazado">Rechazado</option>
              </Form.Select>
              <Form.Text className="text-muted">
                {errors.status?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        {selectStatus==="Depositado" && (
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="puntos">
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>Comprobante del deposito.</Form.Label>
                  <Form.Control
                    type="file"
                    size="sm"
                    {...register("pic", {
                      required: "El campo es obligatorio.",
                    })}
                  />
                </Form.Group>

                <Form.Text className="text-muted">
                  {errors.fecha_deposito?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        )}

        {selectStatus === "Rechazado" && (
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="puntos">
                <Form.Group controlId="formFileSm" className="mb-3">
                  <Form.Label>Mensaje:</Form.Label>

                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Comentario"
                    className="mb-3"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Deja aqui algun comentario."
                      {...register("descripcion", {
                        required: "El campo es obligatorio.",
                      })}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Text className="text-muted">
                  {errors.descripcion?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Button type="submit" className="mb-2">
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

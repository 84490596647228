import React from "react";
import { Table } from "react-bootstrap";

export default function Seller({ info }) {
  console.log(info);
  return (
    <Table >
      <tbody>
        <tr>
          <td>Nombre completo</td>
          <td> {`${info.nombre} ${info.apellido_paterno} ${info.apellido_materno}`}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{info.email}</td>
        </tr>
        <tr>
          <td>Teléfono</td>
          <td>{info.celular}</td>
        </tr>
        <tr>
          <td>Género</td>
          <td>{info.genero}</td>
        </tr>
        <tr>
          <td>Padre/Madre</td>
          <td>{info.progenitor}</td>
        </tr>
        <tr>
          <td>Puntos acumulados</td>
          <td>{info.puntos}</td>
        </tr>
        <tr>
          <td>Cuenta</td>
          <td>{info.status? "Activada": "Desactivada"  }</td>
        </tr>
        <tr>
          <td>Distribuidor</td>
          <td>{info.dealer_id}</td>
        </tr>
        <tr>
          <td>Nombre Comercial</td>
          <td>{info?.dealer}</td>
        </tr>
        <tr>
          <td>Dirección</td>
          <td>{info?.addresses?.completa}</td>
        </tr>

        
      </tbody>
    </Table>
  );
}

import { useState } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from "reactstrap";
import "./menu.css";
import useUser from "../../hooks/useUser";
import Logo from "components/Logo";
import pic from "../../assets/images/L-STIHL.png";

export default function Index(props) {
  const { Roles, isLogged } = useUser();
  const [open, setopen] = useState(false);
  const noRefCheck = () => {
    setopen(!open);
  };
  const navigate = useNavigate();
  const matchHome = useMatch("/");
  const matchLogin = useMatch("/login");

  const goToRoute = (ruta) => {
    navigate("/" + ruta);
  };

  const linkLogo = isLogged ? "dashboard" : "login";

  return (
    <>
      {matchHome === null && matchLogin === null && (
        <Navbar expand="md" style={{ background: "#f37a1f" }} dark>
          {isLogged === false ? (
            /*               onClick={() => goToRoute(linkLogo)}
              style={{ cursor: "pointer" }} */
            <NavbarBrand>
              <Logo style={{ height: "40px" }} />
            </NavbarBrand>
          ) : (
            (Roles === "Analista" && (
              <NavbarBrand
                onClick={() => goToRoute("productos-registrados")}
                style={{ cursor: "pointer" }}
              >
                <Logo style={{ height: "40px" }} />
              </NavbarBrand>
            )) ||
            ((Roles === "Vendedor" || Roles === "Propietario") && (
              <NavbarBrand
                onClick={() => goToRoute("dashboard-vendedor")}
                style={{ cursor: "pointer" }}
              >
                <Logo style={{ height: "40px" }} />
              </NavbarBrand>
            )) ||
            (Roles === "Admin" && (
              <NavbarBrand
                onClick={() => goToRoute("dashboard")}
                style={{ cursor: "pointer" }}
              >
                <Logo style={{ height: "40px" }} />
              </NavbarBrand>
            )) ||
            (Roles === "Promotor" && (
              <NavbarBrand
                onClick={() => goToRoute("dashboard-vendedorEspecializado")}
                style={{ cursor: "pointer" }}
              >
                <Logo style={{ height: "40px" }} />
              </NavbarBrand>
            ))
          )}

          <NavbarBrand>
            <img
              src={pic}
              alt="Logo-1"
              border="0"
              className={isLogged ? "position-static" : "position-absolute"}
              style={{
                height: "56px",
                left: "calc(100vw / 2 - 68px)",
                top: "5px",
              }}
            />
          </NavbarBrand>

          <NavbarToggler onClick={noRefCheck} />
          <Collapse isOpen={open} navbar>
            <Nav className="w-100 d-flex justify-content-end" navbar>
              {props.children}
            </Nav>
          </Collapse>
        </Navbar>
      )}
    </>
  );
}

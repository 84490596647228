import React, {useState} from 'react'

const Context = React.createContext({})

export function UserContextProvider ({children}) {
  const [token, setToken] = useState(
    () => window.sessionStorage.getItem('token')
  )

  const [IdUser, setIdUser] = useState(
    () => window.sessionStorage.getItem('id_user')
  )

  const [Puntos, setPuntos] = useState(
    () => window.sessionStorage.getItem('puntos')
  )

  const [Concentrados, setConcentrados] = useState(
    () => window.sessionStorage.getItem('concentrado')
  )

  const [Roles, setRoles] = useState(
    () => window.sessionStorage.getItem('rol')
  )

  const [UserNombre, setUserNombre] = useState(
    () => window.sessionStorage.getItem('nombre')
  )

  const [Dealer, setDealer] = useState(
    () => window.sessionStorage.getItem('distribuidor')
  )

  const [Foto, setFoto] = useState(
    () => window.sessionStorage.getItem('foto')
  )


  return <Context.Provider value={{
    token,
    setToken,
    IdUser,
    setIdUser,
    Puntos,
    setPuntos,
    Concentrados,
    setConcentrados,
    Roles,
    setRoles,
    UserNombre,
    setUserNombre,
    Dealer,
    setDealer,
    Foto,
    setFoto
  }}>
    {children}
  </Context.Provider>
}

export default Context
const ENDPOINT = process.env.REACT_APP_API_URL;

// trarer premios
export async function CategoriasGet(token) {
    return fetch(`${ENDPOINT}/categoriasGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function CategoriasAdd(data, token) {
    return fetch(`${ENDPOINT}/categoriasAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'post',
        body: data
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function CategoriasUpdate(data, token) {
    return fetch(`${ENDPOINT}/categoriasUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'post',
        body: data
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function CategoriasDelete(data, token) {
    return fetch(`${ENDPOINT}/categoriasDelete`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'post',
        body: data
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}
const ENDPOINT = process.env.REACT_APP_API_URL;

// trarer registros
export async function CuentaGet({ IdUser, tokenFull }) {
    return fetch(`${ENDPOINT}/cuentaGet/${IdUser}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': tokenFull,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function DistribuidorGet({ tokenFull }) {
    return fetch(`${ENDPOINT}/auth/dealer`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': tokenFull,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function EstadoGet({ tokenFull }) {
    return fetch(`${ENDPOINT}/auth/states`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': tokenFull,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function CuentaUpdate(data, token) {
    return fetch(`${ENDPOINT}/cuentaUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'post',
        body: data
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}
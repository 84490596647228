import React, { useEffect, useState } from "react";
import Select from "react-select";
import useProducts from "../../hooks/useProducts";
import useUser from "../../hooks/useUser";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import Buscando from "../../assets/images/Banner_serie.png";
import NoEncontrado from "../../assets/images/Banner_no_serie2.png";
import { set } from "date-fns";

export default function Ventas({
  item,
  index,
  error,
  successs,
  message,
  Arreglo,
  setArreglo,
  setBoton
}) {
  const { Productos, Status, Mensaje, Valor, ProductosGetService } = useProducts();
  const { tokenFull, IdUser, Puntos, Dealer } = useUser();
  const [Error, setError] = useState(false);
  const ENDPOINT = process.env.REACT_APP_BASE_URL;

  // useEffect(() => {
  //     ProductosGetService(tokenFull)
  // }, [ProductosGetService])

  const ChangeInfo = (value, name) => {
    const newArreglo = Arreglo.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: value };
      } else {
        return item;
      }
    });
    setArreglo(newArreglo);
  };

  // const ChangeData = (value, name, value2, name2, value3, name3) => {
  //     const newArreglo = Arreglo.map((item, i) => {
  //         if (i === index) {
  //             return {
  //                 ...item, [name]: value, [name2]: value2, [name3]: value3,
  //             };
  //         } else {
  //             return item;
  //         }
  //     });
  //     setArreglo(newArreglo);
  // }

  const handleOnChange = (e) => {
    e.preventDefault();
    ChangeInfo(e.target.value, "NoSerie");
    if (e.target.value.length == 9) {
      Arreglo.every((item, i) => {
        if (item.NoSerie == e.target.value) {
          setError(true);
          toast.error("El número de serie ya existe");
          setBoton(false);
          return false;
        } else {
          const data = new FormData();
          data.append("serie", e.target.value);
          data.append("num_dis", Dealer);
          setBoton(true);
          ProductosGetService(data, tokenFull);
          setError(false);
          return true;
        }
      });
    } else {
      //item.modelo == '';
      // Productos[0].modelo = '';
      // Productos[0].puntos = '';
      // Productos[0].imagen = '';
      // Productos[0].SelectedEquipo = '';
      // Productos[0].id = '';
    }
  };

  useEffect(() => {
    if (Productos.length > 0) {
      if (Productos[0] == false) {
        Arreglo.slice(Arreglo.length - 1, 1);
        setArreglo(Arreglo);
      } else {
        const { id, puntos } = Productos[0];
        const newArreglo = Arreglo.map((item, i) => {
          if (i === index) {
            return { ...item, SelectedEquipo: id, PuntosSave: puntos };
          } else {
            return item;
          }
        });
        setArreglo(newArreglo);
      }
    }
  }, [Productos]);

  useEffect(() => {
    if (error) {
      setError(true);
    }
  }, [message, error]);

  useEffect(() => {
    if (successs) {
      setError(false);
    }
  }, [message, successs]);

  const EliminarSerie = (id) => {
    const items = Arreglo.filter((item, index) => index !== id);
    setArreglo(items);
  };

  const handleCheckboxTiendas = (e) => {
    // actualizar el state de Participacion en el arreglo
    const newArreglo = Arreglo.map((item, i) => {
      if (i === index) {
        return { ...item, Participacion: e.target.checked };
      } else {        
        return item;
      }
    });
    setArreglo(newArreglo);
  };

  // funcion de campo de numero entero
  const handleNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      ChangeInfo(e.target.value, "Porcentaje");
    }
  };

  // funcion de campo numero con decimales
  const handleNumberDecimal = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      // mayor a cero
      if (e.target.value > 0) {
        ChangeInfo(e.target.value, "Monto");
      }else{
        ChangeInfo('', "Monto");
      }
    }
  };

  useEffect(() => {
    if(Status == 'disponible'){
      setBoton(true);
    }else{
      setBoton(false);
    }
  }, [Status]);

  console.log(Mensaje);
  console.log(Status);


  return (
    <>
      <div className="row">
        <div className="col-md-6">
          {item.NoSerie != "" ? (
            Productos[0] ? (
              Productos[0].imagen == "" ? (
                <img className="img-fluid " src={Buscando} alt="teser left" />
              ) : (
                <img
                  className="img-fluid "
                  src={
                    ENDPOINT +
                    "/images/productos/" +
                    (Productos[0] ? Productos[0].imagen : "")
                  }
                  alt="producto"
                />
              )
            ) : (
              <img className="img-fluid " src={NoEncontrado} alt="teser left" />
            )
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6">
          <div className="row">
            {index >= 1 ? (
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-danger btn-s"
                  onClick={() => EliminarSerie(index)}
                >
                  X
                </button>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-12">
              <label>No. de serie ( 9 numeros )</label>
              <input
                type="text"
                className={Error ? "form-control is-invalid" : "form-control"}
                maxLength="9"
                value={item.NoSerie}
                onChange={(e) => handleOnChange(e)}
              />
              {(Status === "disponible" && (
                <span class="badge bg-success">Producto Disponible</span>
              )) ||
                (Status === "ocupado" && (
                  <span class="badge bg-danger">
                    Producto ya registrado por otro vendedor
                  </span>
                )) ||
                (Status === "no disponible" && (
                  <span class="badge bg-warning">Producto No Disponible</span>
                )) ||
                (Status ===
                  "producto no encontrado en la familia economica" && (
                  <span class="badge bg-danger">
                    {Mensaje}
                  </span>
                ))}
              <br />
            </div>
            <div className="col-md-12">
              <label>Modelo</label>
              <input
                type="text"
                className="form-control"
                disabled={true}
                value={Productos[0] ? Productos[0].modelo : ""}
                onChange={(e) => ChangeInfo(e.target.value, "SelectedEquipo")}
              />
              <br />
            </div>
            {/* <div className='col-md-12'>
                        <label>Seleccionar equipo</label>
                        <Select
                            value={item.SelectedEquipo}
                            onChange={e => {
                                ChangeData(e, 'SelectedEquipo', e.puntos, 'PuntosSave', e.imagen, 'ImagenModelo')
                            }}
                            options={Productos['data']}
                            placeholder={"Equipos"}
                        /><br />
                    </div> */}
            <div className="col-md-12">
              <label>Puntos para tu ranking</label>
              <input
                type="text"
                className="form-control"
                disabled={true}
                value={Productos[0] ? Productos[0].puntos : ""}
                onChange={(e) => ChangeInfo(e.target.value, "PuntosSave")}
              />
              <br />
            </div>
            {/* {(Productos[0] && Productos[0].modelo === "FS 38" && (
              <div className="col-md-12">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    checked={item.Participacion}
                    onChange={(e) => handleCheckboxTiendas(e)}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Participación en la campaña
                  </label>
                </div>
              </div>
            )) ||
              (Productos[0] && Productos[0].modelo === "FS 55" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              )) ||
              (Productos[0] && Productos[0].modelo === "FS 55 R" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              )) ||
              (Productos[0] && Productos[0].modelo === "FS 120" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              )) ||
              (Productos[0] && Productos[0].modelo === "FS 120 R" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              )) ||
              (Productos[0] && Productos[0].modelo === "FS 250" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              )) ||
              (Productos[0] && Productos[0].modelo === "SR 420" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              )) ||
              (Productos[0] && Productos[0].modelo === "SR 430" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              )) ||
              (Productos[0] && Productos[0].modelo === "SR 450" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              )) ||
              (Productos[0] && Productos[0].modelo === "MS 210" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              )) ||
              (Productos[0] && Productos[0].modelo === "MS 250" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              )) ||
              (Productos[0] && Productos[0].modelo === "MS 310" && (
                <div className="col-md-12">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={item.Participacion}
                      onChange={(e) => handleCheckboxTiendas(e)}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Participación en la campaña
                    </label>
                  </div>
                </div>
              ))}

            {item.Participacion ? (
              <>
                <div className="col-md-6">
                  <label>Porcentaje de descuento</label>
                  <input
                    type="text"
                    className="form-control"
                    value={item.Porcentaje}
                    onChange={(e) => handleNumber(e)}
                  />
                  <br />
                </div>
                <div className="col-md-6">
                  <label>Precio del equipo con descuento aplicado</label>
                  <input
                    type="text"
                    className="form-control"
                    value={item.Monto}
                    onChange={(e) => handleNumberDecimal(e)}
                  />
                  <br />
                </div>
              </>
            ) : (
              ""
            )} */}

            <input
              type="hidden"
              className="form-control"
              disabled={true}
              value={Productos[0] ? Productos[0].id : ""}
              onChange={(e) => ChangeInfo(e.target.value, "ModeloId")}
            />
            <br />
          </div>
        </div>
        <div className="col-md-12">
          <hr style={{ background: "#ed7100" }} />
        </div>
      </div>
    </>
  );
}

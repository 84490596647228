import React, {useState, useEffect} from "react";
import useDashboard from "../../hooks/useDashboard";
import useUser from "../../hooks/useUser";
import toast, {Toaster} from "react-hot-toast";
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridPDFExport} from "@progress/kendo-react-pdf";
import {filterBy} from "@progress/kendo-data-query";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  {
    field: "folio",
    dir: "desc",
  },
];

const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

export default function VentasPromotor() {
  const {success, message, error, Ventas, VentasAddService} = useDashboard();
  const {tokenFull, IdUser, Roles} = useUser();
  const [Desde, setDesde] = useState("");
  const [Hasta, setHasta] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);

  const ProductosAgregados = () => {
    toast.loading("Registrando...");
    const data = new FormData();
    data.append("rol", Roles);
    data.append("user_id", IdUser);
    data.append("desde", Desde);
    data.append("hasta", Hasta);

    VentasAddService(data, tokenFull);
  };

  useEffect(() => {
    if (success) {
      toast.dismiss();
      toast.success(message);
    }
  }, [message, success]);

  const _export = React.useRef(null);

  const _pdfExport = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const exportPDF = () => {
    if (_pdfExport.current !== null) {
      _pdfExport.current.save();
    }
  };

  const pageChange = (event) => {
    setPage(event.page);
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const fecha = (fecha) => {
    let meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    let a = fecha.substr(0, 4);
    let m = fecha.substr(5, 2);
    let d = fecha.substr(8);
    return d + " de " + meses[m - 1] + " de " + a;
  };

  const ExportExcel = (array) => {
    const newArray = array.map((res) => {
      delete res.updated_at;
      delete res.created_at;
      delete res.remember_token;
      delete res.escolaridad;
      delete res.nota;
      delete res.role;
      delete res.active;
      delete res.password;
      delete res.email_verified_at;
      delete res.email;
      delete res.concentrado;
      delete res.cumpleanos;
      delete res.progenitor;
      delete res.genero;
      delete res.celular;
      delete res.id;
      delete res.foto;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
    const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, "Ventas" + fileExtension);
  };
  return (
    <>
      <Toaster />
      <h2 className="titulo2">Ventas</h2>
      <div className="row">
        <div className="col-md-4">
          <label>Desde</label>
          <input
            type="date"
            className="form-control"
            value={Desde}
            onChange={(e) => setDesde(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label>Hasta</label>
          <input
            type="date"
            className="form-control"
            value={Hasta}
            onChange={(e) => setHasta(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => ProductosAgregados()}
            style={{marginTop: 20}}
          >
            Buscar
          </button>
        </div>
      </div>
      <br />
      {Ventas.length > 0 ? (
        <>
          {Roles === "Admin" ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <button
                    className="btn btn-success"
                    onClick={() => ExportExcel(Ventas)}
                  >
                    Exportar Excel
                  </button>
                </div>
              </div>
              <br />
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      <div className="row">
        <div className="col-md-12">
          <ExcelExport fileName="Ventas" data={Ventas} ref={_export}>
            <Grid
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              pageable={true}
              data={filterBy(
                Ventas.slice(page.skip, page.take + page.skip),
                filter
              )}
              skip={page.skip}
              take={page.take}
              total={Ventas.length}
              onPageChange={pageChange}
              style={{
                height: "420px",
              }}
            >
              <GridNoRecords>No hay informacion disponible</GridNoRecords>
              {Roles === "Admin" ? (
                <GridColumn
                  field="Vendedor"
                  filter="text"
                  cell={(a) => {
                    let nombre = a.dataItem.nombre;
                    let apellido_paterno = a.dataItem.apellido_paterno;
                    let apellido_materno = a.dataItem.apellido_materno;
                    return (
                      <td style={{marginTop: 10, textAlign: "center"}}>
                        {nombre} {apellido_paterno} {apellido_materno}
                      </td>
                    );
                  }}
                />
              ) : (
                ""
              )}
              {Roles === "Admin" ? (
                <GridColumn
                  field="Distribuidor"
                  filter="text"
                  cell={(a) => {
                    let dealer_id = a.dataItem.dealer_id;
                    return (
                      <td style={{marginTop: 10, textAlign: "center"}}>
                        {dealer_id}
                      </td>
                    );
                  }}
                />
              ) : (
                ""
              )}
              <GridColumn field="modelo" title="Modelo" />
              <GridColumn field="serie" title="No. Serie" />
              <GridColumn
                field="Fecha"
                filter="text"
                cell={(a) => {
                  let fecha_venta = a.dataItem.Fecha;
                  return (
                    <td style={{marginTop: 10, textAlign: "center"}}>
                      {fecha(fecha_venta)}
                    </td>
                  );
                }}
              />
              {Roles === "Admin" ? (
                <GridColumn
                  field="Venta en pesos"
                  filter="text"
                  format={null}
                  cell={(e) => {
                    let total = e.dataItem.Venta;
                    return (
                      <td style={{marginTop: 10, textAlign: "right"}}>
                        {"$" + formatNumber(total)}
                      </td>
                    );
                  }}
                />
              ) : (
                ""
              )}
{/* 
              <GridColumn
                field="Puntos obtenidos para el ranking"
                filter="text"
                format={null}
                cell={(e) => {
                  let puntos = e.dataItem.puntos;
                  return (
                    <td style={{marginTop: 10, textAlign: "right"}}>
                      {formatNumber(puntos)}
                    </td>
                  );
                }}
              />
 */}
              {Roles === "Promotor" ? (
                <GridColumn
                  field="Estatus de la venta"
                  filter="text"
                  format={null}
                  cell={(e) => {
                    let status = e.dataItem.status;
                    return (
                      <td style={{marginTop: 10, textAlign: "right"}}>
                        {status === "validado" ? (
                          <span class="badge bg-success">Validado</span>
                        ) : status === "no validado" ? (
                          <span class="badge bg-danger">No validado</span>
                        ) : status === "en espera" ? (
                          <span class="badge bg-warning">En espera</span>
                        ) : (
                          "No definido"
                        )}
                      </td>
                    );
                  }}
                />
              ) : (
                ""
              )}

            </Grid>
          </ExcelExport>
        </div>
      </div>
    </>
  );
}

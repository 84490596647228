import React, { useState, useEffect } from 'react'
import useCategorias from '../../hooks/useCategorias';
import useUser from '../../hooks/useUser';
import toast, { Toaster } from 'react-hot-toast';
import ModalCategoria from '../Modal/ModalCategoria';

export default function HomePage() {
    const { success, message, error, Categorias, CategoriasGetService, CategoriasDeleteService } = useCategorias();
    const { tokenFull, IdUser, Puntos } = useUser();
    const [Pagina, setPagina] = useState(0);
    const [modal, setModal] = useState(false);
    const [Update, setUpdate] = useState(false);
    const [CurrentPagina, setCurrentPagina] = useState(1);
    const [Id, setId] = useState('');
    const [Nombre, setNombre] = useState('');
    const ENDPOINT = process.env.REACT_APP_API_URL.substr(0, 43);

    const toggle = () => {
        setModal(!modal);
        setUpdate(false);
        setId('');
        setNombre('');
    }

    useEffect(() => {
        if (Categorias.length > 0) {
            setPagina(Math.round(Categorias.length / 10));
        }
    }, [Categorias])

    const getPaginatedData = () => {
        const startIndex = CurrentPagina * 10 - 10;
        const endIndex = startIndex + 10;
        return Categorias.slice(startIndex, endIndex);
    };


    useEffect(() => {
        CategoriasGetService(tokenFull)
    }, [CategoriasGetService])

    const previousPage = () => {
        setCurrentPagina(CurrentPagina => CurrentPagina - 1);
    }

    const nextPage = () => {
        setCurrentPagina(CurrentPagina => CurrentPagina + 1);
    }

    const categoriasEliminar = (dato) => {
        const data = new FormData();
        data.append("id", dato);
        CategoriasDeleteService(data, tokenFull);
    }

    useEffect(() => {
        if (success == true) {
            CategoriasGetService(tokenFull)
        }
    }, [CategoriasGetService, success])

    return <>
        <Toaster />
        <ModalCategoria modal={modal}
            toggle={toggle}
            Id={Id}
            Update={Update}
            Nombre={Nombre}
            setNombre={setNombre}
            CategoriasGetService={CategoriasGetService}
        />
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <h2>Listado de Categorias</h2>
                </div>
                <div className='col-md-6'>
                    <a className="btn btn-primary float-end" onClick={toggle}>Agregar Categoria</a>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-12'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Editar</th>
                                <th>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getPaginatedData().map((item, index) => {
                                    return <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.nombre}</td>
                                        <td><button className='btn btn-warning' onClick={() => {
                                            setId(item.id);
                                            setNombre(item.nombre);
                                            setUpdate(true);
                                            setModal(true);
                                        }}>Editar</button></td>
                                        <td><button className='btn btn-danger' onClick={(() => categoriasEliminar(item.id))}>Eliminar</button></td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-12'>
                    <button onClick={previousPage}>Previous Page</button>
                    <button onClick={nextPage}>Next Page</button>
                </div>
            </div>
        </div>
    </>
}
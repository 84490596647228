import { useCallback, useState } from 'react';
import { RecuperarPost } from '../services/recuperar';

export default function useCategorias() {
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const RecuperarPostService = useCallback((
        data
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        RecuperarPost(
            data
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let email = errores['email'];
                if (email != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: email[0],
                        success: false
                    })
                }
            } else {
                if (response.guardado == true) {
                    setState({
                        loading: false,
                        error: false,
                        message: '¡Le hemos enviado por correo electrónico su enlace de restablecimiento de contraseña!',
                        success: true
                    })

                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al enviar correo electrónico para el restablecimiento de contraseña',
                        success: false
                    })
                }
            }
        })
    }, [])

    
    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        RecuperarPostService
    }
}
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Chip,
  IconButton,
  TableFooter,
  TablePagination,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import useUser from "../../hooks/useUser";
import Swal from "sweetalert2";
import useProducts from "../../hooks/useProducts";

const columns = [
  { id: "id", label: "#", minWidth: 170 },
  { id: "Serial", label: "Serial", minWidth: 170 },
  { id: "producto", label: "Producto", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  {
    id: "customer_number",
    label: "Distribuidor al que pertenece",
    minWidth: 100,
  },
  { id: "familia", label: "Familia economica", minWidth: 100 },
  { id: "no", label: "No. Familia", minWidth: 100 },
  { id: "vendedor", label: "Vendedor", minWidth: 100 },
  { id: "distribuidor", label: "Distribuidor", minWidth: 100 },
  { id: "fecha_venta", label: "Fecha de registro", minWidth: 100 },
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function Index() {
  const { tokenFull, IdUser, Puntos } = useUser();
  const { success, message, error, BuscarSerialGetService, Seriales } =
    useProducts();
  const [Serie, setSerie] = useState("");
  const [Pagina, setPagina] = useState(0);

  const BuscarPorMes = () => {
    const data = new FormData();
    data.append("serial", Serie);

    BuscarSerialGetService(data, tokenFull);

    Swal.fire({
      title: "Buscando...",
      text: "Loading...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: "question",
      onOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      setSerie("");
    }
  }, [message, success]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <h2 className="titulo2">Buscar Serial</h2>
            <div className="col-md-4">
              <label>Serial</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Numero de serie"
                  aria-label="Numero de serie"
                  aria-describedby="button-addon2"
                  onChange={(e) => setSerie(e.target.value)}
                />
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                  onClick={() => BuscarPorMes()}
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-md-12">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        className="text-center"
                        style={{ background: "#ed7100" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Seriales.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((row, index) => {
                    console.log(row);
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell key={index} className="text-center">
                          {(page + 1) * rowsPerPage - rowsPerPage + (index + 1)}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.serial}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.model}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.status == "disponible" ? (
                            <span
                              className="badge bg-success"
                              style={{
                                width: 100,
                                borderRadius: 10,
                                textTransform: "capitalize",
                              }}
                            >
                              {row.status}
                            </span>
                          ) : (
                            <span
                              className="badge bg-danger"
                              style={{
                                width: 100,
                                borderRadius: 10,
                                textTransform: "capitalize",
                              }}
                            >
                              {row.status}
                            </span>
                          )}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.customer_number}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.FAMILIA_ECONOMICA}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.Bill_to}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.nombre} {row.apellido_paterno}{" "}
                          {row.apellido_materno}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.dealer_id}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.fecha_venta}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "Todos", value: -1 },
                      ]}
                      colSpan={10}
                      count={Seriales.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "filas por página",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}

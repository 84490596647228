import { useCallback, useState } from "react";
import {
  ProductosGet,
  ProductoAdd,
  RegistrosGet,
  RegistroUpdate,
  RegistroUpdateDate,
  RegistroUpdateValidarFactura,
  PuntosGet,
  UtilidadGet,
  FacturasDashboardGet,
  BuscarSerialGet,
  VedndedorUpdate,
  VendedorGet,
  BuscarMatrizGet,
} from "../services/productos";

export default function useCategorias() {
  const [Productos, setProductos] = useState([]);
  const [Punto, setPunto] = useState(0);
  const [Utilidad, setUtilidad] = useState(0);
  const [Ventas, setVentas] = useState([]);
  const [ValidadoFull, setValidadoFull] = useState(0);
  const [NoValidadoFull, setNoValidadoFull] = useState(0);
  const [EnEsperaFull, setEnEsperaFull] = useState(0);
  const [ValidadoDay, setValidadoDay] = useState(0);
  const [NoValidadoDay, setNoValidadoDay] = useState(0);
  const [EnEsperaDay, setEnEsperaDay] = useState(0);
  const [Seriales, setSeriales] = useState([]);
  const [Vendedor, setVendedor] = useState([]);
  const [Valor, setValor] = useState(false);
  const [Sucursales, setSucursales] = useState([]);
  const [Status, setStatus] = useState("");
  const [Mensaje, setMensaje] = useState("");
  const [State, setState] = useState({
    loading: false,
    error: false,
    message: "",
    success: false,
    successs: false,
  });

  const ProductosGetService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
      successs: false,
    });
    ProductosGet(data, token).then((response) => {
      let errores = response.errors;
      if (errores != undefined) {
        let serie = errores["serie"];
        if (serie != null) {
          setState({
            loading: false,
            error: true,
            message: serie[0],
            success: false,
            successs: false,
          });
        }
      } else {
        if (response.length == 0) {
          setState({
            loading: false,
            error: true,
            message: "Producto no encontrado o no pertenece a su sucursal",
            success: false,
            successs: false,
          });
          setProductos([false]);
          setValor(0);
          setStatus(response.status);
          setMensaje(response.mensaje);
        } else {
          setState({
            loading: false,
            error: false,
            message: "Producto encontrado con exito",
            success: false,
            successs: false,
          });
          setProductos(response.registros);
          setValor(response.length);
          setStatus(response.status);
          setMensaje(response.mensaje);
        }
      }
    });
  }, []);

  const ProductosAddService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
      successs: false,
    });
    ProductoAdd(data, token).then((response) => {
      let errores = response.errors;
      if (errores != undefined) {
        let factura = errores["factura"];
        let fecha = errores["fecha"];
        if (fecha != null) {
          setState({
            loading: false,
            error: true,
            message: fecha[0],
            success: false,
            successs: false,
          });
        }
        if (factura != null) {
          setState({
            loading: false,
            error: true,
            message: factura[0],
            success: false,
            successs: false,
          });
        }
      } else {
        if (response.guardado == true) {
          setState({
            loading: false,
            error: false,
            message:
              "Gracias por tu registro en unos minutos va ser validada y se te asignaran los puntos",
            success: true,
            successs: false,
          });
        } else {
          setState({
            loading: false,
            error: true,
            message: "Error al realizar registro",
            success: false,
            successs: false,
          });
        }
      }
    });
  }, []);

  const RegistrosGetService = useCallback(
    ({ IdUser, tokenFull, mesActual }) => {
      RegistrosGet({ IdUser, tokenFull, mesActual }).then((response) => {
        setVentas(response.registros);
        setValidadoFull(response.valido);
        setNoValidadoFull(response.no_valido);
        setEnEsperaFull(response.en_espera);
        setValidadoDay(response.valido_d);
        setNoValidadoDay(response.no_valido_d);
        setEnEsperaDay(response.en_espera_d);
      });
    },
    []
  );

  const RegistroUpdateService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
      successs: false,
    });
    RegistroUpdate(data, token).then((response) => {
      if (response == true) {
        setState({
          loading: false,
          error: false,
          message: "Registro actualizado con exito",
          success: true,
          successs: false,
        });
      } else {
        setState({
          loading: false,
          error: true,
          message: "Error al actualizar registro",
          success: false,
          successs: false,
        });
      }
    });
  }, []);

  const RegistroUpdateDateService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
      successs: false,
    });
    RegistroUpdateDate(data, token).then((response) => {
      let errores = response.errors;
      if (errores !== undefined) {
        let fecha = errores["fecha"];
        if (fecha != null) {
          setState({
            loading: false,
            error: true,
            message: fecha[0],
            success: false,
          });
        }
      } else {
        if (response === true) {
          setState({
            loading: false,
            error: false,
            message: "Registro actualizado con exito",
            success: true,
            successs: false,
          });
        } else {
          setState({
            loading: false,
            error: true,
            message: "Error al actualizar registro",
            success: false,
            successs: false,
          });
        }
      }
    });
  }, []);

  const RegistroUpdateValidarFacturaService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
      successs: false,
    });
    RegistroUpdateValidarFactura(data, token).then((response) => {
        if (response === true) {
          setState({
            loading: false,
            error: false,
            message: "Registro actualizado con exito",
            success: true,
            successs: false,
          });
        } else {
          setState({
            loading: false,
            error: true,
            message: "Error al actualizar registro",
            success: false,
            successs: false,
          });
        }
    });
  }, []);

  const PuntosGetService = useCallback(({ Id, tokenFull }) => {
    PuntosGet({ Id, tokenFull }).then((response) => {
      setPunto(response.value);
    });
  }, []);

  const UtilidadGetService = useCallback(({ Id, tokenFull }) => {
    UtilidadGet({ Id, tokenFull }).then((response) => {
      setUtilidad(response.value);
    });
  }, []);

  const BuscarSerialGetService = useCallback((data, tokenFull) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
      successs: false,
    });
    BuscarSerialGet(data, tokenFull).then((response) => {
      let errores = response.errors;
      if (errores != undefined) {
        let serial = errores["serial"];
        if (serial != null) {
          setState({
            loading: false,
            error: true,
            message: serial[0],
            success: false,
            successs: false,
          });
        }
      } else {
        if(response.length > 0){
          setState({
            loading: false,
            error: false,
            message: "Serial encontrado con exito",
            success: true,
            successs: false,
          });
          setSeriales(response);
        }else{
          setState({
            loading: false,
            error: true,
            message: "Serial no encontrado",
            success: false,
            successs: false,
          });
          setSeriales([]);
        }
      }
    });
  }, []);

  const VendedorGetService = useCallback((data, tokenFull) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
      successs: false,
    });
    VendedorGet(data, tokenFull).then((response) => {
      let errores = response.errors;
      if (errores != undefined) {
        let email = errores["email"];
        if (email != null) {
          setState({
            loading: false,
            error: true,
            message: email[0],
            success: false,
            successs: false,
          });
        }
      } else {
        if(response.length > 0){
          setState({
            loading: false,
            error: false,
            message: "Vendedor encontrado con exito",
            success: true,
            successs: false,
          });
          setVendedor(response);
        }else{
          setState({
            loading: false,
            error: true,
            message: "No se pudo encontral al vendedor",
            success: false,
            successs: false,
          });
          setVendedor([]);
        }
      }
    });
  }, []);

  const BuscarMatrizGetService = useCallback((data, tokenFull) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
      successs: false,
    });
    BuscarMatrizGet(data, tokenFull).then((response) => {
      let errores = response.errors;
      if (errores != undefined) {
        let distribuidor = errores["distribuidor"];
        if (distribuidor != null) {
          setState({
            loading: false,
            error: true,
            message: distribuidor[0],
            success: false,
            successs: false,
          });
        }
      } else {
        if(response.length > 0){
          setState({
            loading: false,
            error: false,
            message: "Sucursales encontradas con exito",
            success: true,
            successs: false,
          });
          setSucursales(response);
        }else{
          setState({
            loading: false,
            error: true,
            message: "No se pudo encontrar la sucursales",
            success: false,
            successs: false,
          });
          setSucursales([]);
        }
      }
    });
  }, []);

  const VedndedorUpdateService = useCallback((data, tokenFull) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
      successs: false,
    });
    VedndedorUpdate(data, tokenFull).then((response) => {
      let errores = response.errors;
      if (errores != undefined) {
        let dealer_id = errores["dealer_id"];
        if (dealer_id != null) {
          setState({
            loading: false,
            error: true,
            message: dealer_id[0],
            success: false,
            successs: false,
          });
        }
      } else {
        if (response.guardado == true) {
          setState({
            loading: false,
            error: true,
            message: "Vendedor actualizado con exito",
            success: false,
            successs: true,
          });
        } else {
          setState({
            loading: false,
            error: false,
            message: "Error al actualizar vendedor",
            success: false,
            successs: false,
          });
        }
      }
    });
  }, []);

  return {
    loading: State.loading,
    error: State.error,
    message: State.message,
    success: State.success,
    successs: State.successs,
    Productos,
    setProductos,
    Valor,
    Ventas,
    ValidadoFull,
    NoValidadoFull,
    EnEsperaFull,
    ValidadoDay,
    NoValidadoDay,
    EnEsperaDay,
    Punto,
    Utilidad,
    Seriales,
    Vendedor,
    Sucursales,
    Status,
    Mensaje,
    ProductosGetService,
    ProductosAddService,
    RegistrosGetService,
    RegistroUpdateService,
    RegistroUpdateDateService,
    RegistroUpdateValidarFacturaService,
    PuntosGetService,
    UtilidadGetService,
    BuscarSerialGetService,
    VendedorGetService,
    VedndedorUpdateService,
    BuscarMatrizGetService
  };
}

const ENDPOINT = process.env.REACT_APP_API_URL;

// trarer productos con serial
export async function ProductosGet(data, token) {
  return fetch(`${ENDPOINT}/sales_products`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ProductoAdd(data, token) {
  return fetch(`${ENDPOINT}/registerproducto`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

// trarer registros
export async function RegistrosGet({ IdUser, tokenFull, mesActual }) {
  return fetch(`${ENDPOINT}/register_productGet`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: tokenFull,
    },
    method: "post",
    body: JSON.stringify({ mesActual }),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function RegistroUpdate(data, token) {
  return fetch(`${ENDPOINT}/registerproductoupdate`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function RegistroUpdateDate(data, token) {
  return fetch(`${ENDPOINT}/dateupdate`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function RegistroUpdateValidarFactura(data, token) {
  return fetch(`${ENDPOINT}/validatepdfupdate`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

// trarer puntos
export async function PuntosGet({ Id, tokenFull }) {
  return fetch(`${ENDPOINT}/settings/${Id}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: tokenFull,
    },
    method: "get",
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

// trarer utilidad
export async function UtilidadGet({ Id, tokenFull }) {
  return fetch(`${ENDPOINT}/settings/${Id}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: tokenFull,
    },
    method: "get",
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function BuscarSerialGet(data, tokenFull) {
  return fetch(`${ENDPOINT}/buscar-serial`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${tokenFull}`,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

// trarer puntos
export async function VendedorGet(data, tokenFull) {
  return fetch(`${ENDPOINT}/vendedor`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${tokenFull}`,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function BuscarMatrizGet(data, tokenFull) {
  return fetch(`${ENDPOINT}/buscar-matriz`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${tokenFull}`,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

// trarer productos con serial
export async function VedndedorUpdate(data, token) {
  return fetch(`${ENDPOINT}/editar-vendedor`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

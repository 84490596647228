import React, { useContext, useEffect, useState } from "react";
import cardService from "services/card";

import { ModalForm } from "components/ModalForm";
import { useForm } from "react-hook-form";
import { Col, Container, Form, Row, Button, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputGroup } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Context from "context/UserContext";
import useUser from "../../hooks/useUser";
import Swal from "sweetalert2";
import { date } from "yup/lib/locale";
import { format, subMonths, parseISO } from "date-fns";
import { es } from "date-fns/locale";

const obligatorio = "Los campos de la tarjeta son obligatorios.";
const tarjeta = "Escribir 4 números por recuadro.";
const interbank = "Debes escribir 18 números de la clave interbancaria.";
const schema = yup.object().shape({
  bank: yup.string().required(obligatorio),
  card_number1: yup
    .string()
    .trim()
    .matches(/^\d{4}$/, tarjeta)
    .required(obligatorio),
  card_number2: yup
    .string()
    .trim()
    .matches(/^\d{4}$/, tarjeta)
    .required(obligatorio),
  card_number3: yup
    .string()
    .trim()
    .matches(/^\d{4}$/, tarjeta)
    .required(obligatorio),
  card_number4: yup
    .string()
    .trim()
    .matches(/^\d{4}$/, tarjeta)
    .required(obligatorio),
  interbank_key: yup
    .string()
    .trim()
    .matches(
      /^\d{18}$/,
      {
        excludeEmptyString: true,
        message: interbank,
      },
      tarjeta
    )
    .nullable(true),
});

export default function Reddem() {
  const {
    control,
    register,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  let navigate = useNavigate();
  const [registered, setRegistered] = useState(false);
  const { token } = useContext(Context);
  const { IdUser, Concentrados } = useUser();
  const [card, setCard] = useState([]);
  const [verifyDeposit, setVerifyDeposit] = useState(false);
  const [deposit, setDeposit] = useState({});
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const lastmonth = format(subMonths(new Date(), 1), "M", { locale: es });

  const checkDeposit = async (token, user_id, month) => {
    setIsButtonVisible(false);
    try {
      const { data } = await cardService.verifyDeposit(token, user_id, month);
      if (data && data.hasOwnProperty("status") && data.status === true) {
        setVerifyDeposit(true);
        setDeposit(data.deposito);
      }
    } catch (error) {
      console.error("Error al verificar el depósito:", error);
    }

    setIsButtonVisible(true);
  };

  useEffect(() => {
    cardService
      .showCard(token, IdUser)
      .then(({ data }) => {
        const { card_number } = data;
        const card_number1 = card_number.slice(0, 4);
        const card_number2 = card_number.slice(4, 8);
        const card_number3 = card_number.slice(8, 12);
        const card_number4 = card_number.slice(12, 16);
        setCard({
          ...data,
          card_number1,
          card_number2,
          card_number3,
          card_number4,
        });
      })
      .catch((error) => console.log(error));

    checkDeposit(token, IdUser, lastmonth);
  }, []);

  useEffect(() => {
    reset(card);
  }, [card]);

  const onSubmit = async (values) => {
    await checkDeposit(token, IdUser, lastmonth);

    let {
      card_number1,
      card_number2,
      card_number3,
      card_number4,
      ...databank
    } = values;

    const card_number = card_number1.concat(
      card_number2,
      card_number3,
      card_number4
    );
    let campos = {
      card_number: card_number,
      user_id: IdUser,
      mes_solicitado: subMonths(new Date(), 1).getMonth(),
    };

    if (card.hasOwnProperty("id")) {
      campos["id"] = card.id;
    }

    const inputs = Object.assign(databank, campos);

    const formData = new FormData();
    for (var key in inputs) {
      formData.append(key, inputs[key]);
    }
    if (verifyDeposit) {
      Swal.fire({
        icon: "error",
        html: `<p>Ya se realizó la solicitud de depósito el día ${format(
          parseISO(deposit.fecha),
          "dd-MM-yyyy"
        )}.<p>Consulta la sección de Depósitos</p>`,
        timer: 11000,
        confirmButtonColor: "#3b82f6",
        timerProgressBar: true,
      });
    } else {
      console.log("entre a crear un nuevo registro", verifyDeposit);
      setIsButtonVisible(false);
      cardService.storeCard(token, formData).then((response) => {
        Swal.fire({
          icon: "success",
          html: `<h1>Enviado exitosamente</h1><p> ¡Felicidades! Por tu posición acabas de canjear la cantidad de ${Concentrados} MXN. Tu depósito se realizará entre los días 15 y 18 de cada mes de lunes a viernes y días hábiles en un horario de -9am a 4pm.</p>`,
          timer: 10000,
          confirmButtonColor: "#3b82f6",
          timerProgressBar: true,
        }).then(() => {
          navigate("/compras");
        });
        //setRegistered(true);
        // UX
        //navigate("/compras");
        setIsButtonVisible(true);
      });
    }
  };
  return (
    <Container fluid="xxl">
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Row className="justify-content-center mt-5">
          <Col xs={12} md={6} className="titulo2">
            Instrucciones:
          </Col>
        </Row>
        <Row className="justify-content-center mt-1">
          <Col xs={12} md={6}>
            Para canjear tu dinero en efectivo es necesario que proporciones un
            número de cuenta CLABE que podrás encontrar en tu estado de cuenta
            y/o los dígitos de tu tarjeta. Tu depósito se realizará entre los
            días 15 y 18 de cada mes de lunes a viernes y días hábiles en un
            horario de 9am a 4pm. Recuerda que la cuenta bancaria siempre deberá
            estar a tu nombre.
          </Col>
        </Row>

        <Row className="justify-content-center mt-5">
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="bank">
              <Form.Label>Nombre del Banco</Form.Label>
              <Form.Control
                type="text"
                {...register("bank", {
                  required: "El campo es obligatorio.",
                })}
              />
              <Form.Text className="text-danger">
                {errors.bank?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-center mt-5">
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="card_number">
              <Form.Label>Número de Tarjeta</Form.Label>

              <InputGroup className="mb-3">
                <Form.Control
                  name="card_number1"
                  placeholder="4 dígitos"
                  maxLength={4}
                  {...register("card_number1")}
                />
                <Form.Control
                  placeholder="4 dígitos"
                  maxLength={4}
                  {...register("card_number2")}
                />
                <Form.Control
                  type="text"
                  placeholder="4 dígitos"
                  maxLength={4}
                  {...register("card_number3")}
                />
                <Form.Control
                  type="text"
                  placeholder="4 dígitos"
                  maxLength={4}
                  {...register("card_number4")}
                />
              </InputGroup>

              <Form.Text className="text-danger">
                {
                  (
                    errors.card_number1 ||
                    errors.card_number2 ||
                    errors.card_number3 ||
                    errors.card_number4
                  )?.message
                }
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-center mt-5">
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="interbank_key">
              <Form.Label>CLABE interbancaria</Form.Label>
              <Form.Control
                type="text"
                placeholder="Debes escribir 18 dígitos."
                maxLength={18}
                {...register("interbank_key")}
              />
              <Form.Text className="text-danger">
                {errors.interbank_key?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Form.Text className="text-danger">{errors.phone?.message}</Form.Text>

        <Row className="justify-content-center mt-5">
          <Col xs={12} md={6}>
            {isButtonVisible ? (
              <Button type="submit" className=" mb-3 mt-2 mx-auto">
                Enviar
              </Button>
            ) : (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Col>
        </Row>

      </Form>
    </Container>
  );
}

import { useCallback, useState } from 'react';
import { CategoriasGet, CategoriasAdd, CategoriasUpdate, CategoriasDelete } from '../services/categorias_premios';

export default function useCategorias() {
    const [Categorias, setCategorias] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const CategoriasGetService = useCallback((token) => {
        CategoriasGet(token).then(response => {
            setCategorias(response)
        })
    }, []);

    const CategoriasAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        CategoriasAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                if (nombre != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: nombre[0],
                        success: false
                    })
                }
            } else {
                if (response.guardado == true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Categoria registrada con exito',
                        success: true
                    })

                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar categoria',
                        success: false
                    })
                }
            }
        })
    }, [])

    const CategoriasUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        CategoriasUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                if (nombre != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: nombre[0],
                        success: false
                    })
                }
            } else {
                if (response.guardado == true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Categoria actualizada con exito',
                        success: true
                    })

                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar categoria',
                        success: false
                    })
                }
            }
        })
    }, [])

    const CategoriasDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        CategoriasDelete(
            data, token
        ).then(response => {
            if (response.guardado == true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Categoria eliminada con exito',
                    success: true
                })

            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar categoria',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Categorias,
        CategoriasGetService,
        CategoriasAddService,
        CategoriasUpdateService,
        CategoriasDeleteService
    }
}
const ENDPOINT = process.env.REACT_APP_API_URL;

async function cards(token) {
  return await fetch(`${ENDPOINT}/cards`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}


async function showCard(token,id) {
  return await fetch(`${ENDPOINT}/cards/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}

async function deleteCard( token, id ) {
  return await fetch(`${ENDPOINT}/cards/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    },
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

async function storeCard( token, card) {
  return await fetch(`${ENDPOINT}/cards`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',

      "X-Requested-With": "XMLHttpRequest",
      Authorization: 'Bearer ' + token,
    },
    body: card
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

async function editCard( token, id, data) {
  return await fetch(`${ENDPOINT}/cards/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(data)
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

async function verifyDeposit(token,id,deposit) {
  return await fetch(`${ENDPOINT}/verificar-depositos/${id}/${deposit}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}
export default {cards,deleteCard, storeCard, editCard, showCard, verifyDeposit};
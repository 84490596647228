import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import useUser from "../../../hooks/useUser";
import useProducts from "../../../hooks/useProducts";
import Select from "react-select";
import Swal from "sweetalert2";

const Tipo = [
  { value: "validado", label: "Validado" },
  { value: "no validado", label: "No validado" },
  { value: "en espera", label: "En espera" },
];

export default function ModalDate({
  ModalDates,
  toggleDate,
  Id,
  Fechas,
  setId,
  setFechas,
  RegistrosGetService,
}) {
  const { tokenFull, IdUser } = useUser();
  const { success, message, error, RegistroUpdateDateService } = useProducts();

  useEffect(() => {
    const date = new Date();
    const mes = date.getMonth() + 1;
    const mesActual = mes < 10 ? `0${mes}` : mes;

    RegistrosGetService({ IdUser, tokenFull, mesActual });
  }, [RegistrosGetService]);

  useEffect(() => {
    if (success == true) {
      const date = new Date();
      const mes = date.getMonth() + 1;
      const mesActual = mes < 10 ? `0${mes}` : mes;
      RegistrosGetService({ IdUser, tokenFull, mesActual });
    }
  }, [RegistrosGetService, success]);

  const actualizarStatus = () => {
    const data = new FormData();
    data.append("registro_id", Id);
    data.append("fecha", Fechas);

    RegistroUpdateDateService(data, tokenFull);

    Swal.fire({
      html: "Espere por favor...",
      timer: 2000,
      confirmButtonColor: "#3b82f6",
      timerProgressBar: true,
    });

    toggleDate();
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success]);

  return (
    <>
      <Modal isOpen={ModalDates} toggle={toggleDate} size="lg">
        <ModalHeader toggle={toggleDate}>Actualizar Fecha</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <label>Fecha de venta</label>
              <input
                type="date"
                className="form-control"
                value={Fechas}
                onChange={(e) => setFechas(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary"
            onClick={() => actualizarStatus()}
          >
            Actualizar
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}

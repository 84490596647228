import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import useProducts from "../../../hooks/useProducts";
import Select from "react-select";
import Swal from "sweetalert2";

export default function ModalAdd({
  modal,
  toggle,
  DistribuidorID,
  Email,
  Update,
  tokenFull,
  Id,
  setDistribuidorID,
  setEmail,
  VendedorGetService,
}) {
  const {
    error,
    message,
    success,
    successs,
    BuscarMatrizGetService,
    Sucursales,
    VedndedorUpdateService,
  } = useProducts();
  const [SelectedSucursal, setSelectedSucursal] = useState([]);

  useEffect(() => {
    if (DistribuidorID !== "") {
      const data = new FormData();
      data.append("id", Id);
      data.append("distribuidor", DistribuidorID);

      BuscarMatrizGetService(data, tokenFull);
    }
  }, []);

  const createUser = () => {
    if (Update) {
      const data = new FormData();
      data.append("user_id", Id);
      data.append("dealer_id", DistribuidorID);
      VedndedorUpdateService(data, tokenFull);
      Swal.fire({
        title: "Actualizando usuario",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (successs) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      toggle();
    }
  }, [message, successs]);

  useEffect(() => {
    if (successs === true) {
      const data = new FormData();
      data.append("email", Email);

      VendedorGetService(data, tokenFull);
    }
  }, [successs]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className="backend_titulo" toggle={toggle}>
          {Update ? "Actualizar Vendedor" : "Agregar Vendedor"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <label>Sucursales:</label>
              <Select
                value={SelectedSucursal}
                onChange={(e) => {
                  setDistribuidorID(e.value);
                  setSelectedSucursal(e);
                }}
                options={Sucursales}
                placeholder={DistribuidorID ? DistribuidorID : "Sucursales"}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="backend_boton" onClick={() => createUser()}>
            {Update ? "Actualizar" : "Agregar"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

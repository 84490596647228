import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import useUser from '../../hooks/useUser';
import Ventas from '../../components/Dashboard/Ventas';
import VentasEspecializados from '../../components/Dashboard/VentasEspecializados';
import Vendedor from '../../components/Dashboard/Vendedor';
import VendedorEspecializado from '../../components/Dashboard/VendedorEspecializado';
import Modelo from '../../components/Dashboard/Modelo';
import ModeloEspecializados from '../../components/Dashboard/ModeloEspecializados';
import Distribuidor from '../../components/Dashboard/Distribuidor';
import DistribuidorEspecializados from '../../components/Dashboard/DistribuidorEspecializados';
import Ranking from '../../components/Dashboard/Ranking';
import RankingVendedor from '../../components/Dashboard/RankingVendedor';
import RankingEspecializado from '../../components/Dashboard/RankingEspecializado';
import RankingActual from '../../components/Dashboard/RankingActual';
import RankingActualVendedor from '../../components/Dashboard/RankingActualVendedor';
import RankingActualEspecializado from '../../components/Dashboard/RankingActualEspecializado';
import RankingCampana from '../../components/Dashboard/RankingCampana';
import RankingCampanaMes from '../../components/Dashboard/RankingCampanaMes';
import RankingSeccionesMes from '../../components/Dashboard/RankingSeccionesMes';
import RankingSeccionNorte from '../../components/Dashboard/RankingSeccionNorte';
import RankingSeccionCentro from '../../components/Dashboard/RankingSeccionCentro';
import RankingSeccionSur from '../../components/Dashboard/RankingSeccionSur'
import GanadoresSeccionNorte from '../../components/Dashboard/GanadoresSeccionNorte';
import GanadoresSeccionCentro from '../../components/Dashboard/GanadoresSeccionCentro';
import GanadoresSeccionSur from '../../components/Dashboard/GanadoresSeccionSur';
import GanadoresRegion from '../../components/Dashboard/GanadoresRegion';
import "./dashboard.css";

export default function Item() {
  const { tokenFull, IdUser, Puntos, Roles } = useUser();
  const [Vista, setVista] = useState(0);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (Roles == 'Analista') {
  //     navigate("/productos-registrados");
  //   }
  // }, [Roles]);

  return <>
    <section className="fondo">
      <div className="container" style={{background: '#fff', paddingTop: 15, paddingBottom: 15}}>
        <div className="row">
          <div className="col-lg-2 col-md-3 borde">
            {
              Roles == 'SuperAdmin' &&
              <ul className="list-group margen">
                <li className={Vista == 0 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(0))}>Ventas</li>
                <li className={Vista == 1 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(1))}>Modelo</li>
                <li className={Vista == 2 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(2))}>Vendedor</li>
                <li className={Vista == 3 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(3))}>Distribuidor</li>
                <li className={Vista == 4 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(4))}>Lista de ganadores por mes</li>
                <li className={Vista == 5 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(5))}>Ranking del mes actual</li>

                <li className={Vista == 6 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(6))}>Ventas de vendedores especializados</li>
                <li className={Vista == 7 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(7))}>Modelo de vendedores especializados</li>
                <li className={Vista == 8 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(8))}>Vendedor de vendedores especializados</li>
                <li className={Vista == 9 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(9))}>Distribuidor de vendedores especializados</li>

              </ul>
              || Roles == 'Admin' &&
              <ul className="list-group margen">
                <li className={Vista == 0 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(0))}>Ventas</li>
                <li className={Vista == 1 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(1))}>Modelo</li>
                <li className={Vista == 2 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(2))}>Vendedor</li>
                <li className={Vista == 3 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(3))}>Distribuidor</li>
                <li className={Vista == 4 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(4))}>Lista de ganadores por mes</li>
                <li className={Vista == 13 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(13))}>Ranking del mes actual</li>

                <li className={Vista == 17 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(17))}>Ranking Región Norte</li>
                <li className={Vista == 20 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(20))}>Lista de ganadores Región Norte</li>
                <li className={Vista == 18 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(18))}>Ranking Región Centro</li>
                <li className={Vista == 21 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(21))}>Lista de ganadores Región Centro</li>
                <li className={Vista == 19 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(19))}>Ranking Región Sur</li>
                <li className={Vista == 22 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(22))}>Lista de ganadores Región Sur</li>

                {/* <li className={Vista == 6 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(6))}>Ventas de vendedores especializados</li> */}
                {/* <li className={Vista == 7 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(7))}>Modelo de vendedores especializados</li> */}
                {/* <li className={Vista == 8 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(8))}>Vendedor de vendedores especializados</li> */}
                {/* <li className={Vista == 9 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(9))}>Distribuidor de vendedores especializados</li> */}
                {/* <li className={Vista == 10 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(10))}>Lista de ganadores especializados por mes</li> */}
                {/* <li className={Vista == 11 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(11))}>Ranking del mes actual de vendedores especializados</li> */}
                {/* <li className={Vista == 15 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(15))}>Ranking el mes actual de Bateria</li> */}
                {/* <li className={Vista == 14 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(14))}>Ranking Sep-Oct</li> */}

              </ul>
              || Roles == 'Vendedor' &&
              <ul className="list-group margen">
                <li className={Vista == 0 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(0))}>Ventas</li>
                <li className={Vista == 4 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(4))}>Lista de ganadores por mes</li>
                <li className={Vista == 5 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(5))}>Ranking del mes actual</li>
                {/* <li className={Vista == 15 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(15))}>Ranking el mes actual de Bateria</li> */}
                {/* <li className={Vista == 14 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(14))}>Ranking Sep-Oct</li> */}
                <li className={Vista == 16 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(16))}>Ranking Regiones</li>
                <li className={Vista == 23 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(23))}>Lista de ganadores por Regiones</li>
              </ul>
              || Roles == 'Promotor' &&
              <ul className="list-group margen">
                <li className={Vista == 0 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(0))}>Ventas</li>
                <li className={Vista == 4 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(10))}>Lista de ganadores por mes</li>
                <li className={Vista == 5 ? "list-group-item menu_dash_activo" : "list-group-item menu_dash"} onClick={(() => setVista(11))}>Ranking del mes actual</li>
              </ul>
            }
          </div>
          <div className="col-lg-10 col-md-9">
            {
              Vista == 0 &&
              <Ventas />
              || Vista == 1 &&
              <Modelo />
              || Vista == 2 &&
              <Vendedor />
              || Vista == 3 &&
              <Distribuidor />
              || Vista == 4 &&
              <Ranking />
              || Vista == 5 &&
              <RankingActual />
              || Vista == 6 &&
              <VentasEspecializados />
              || Vista == 7 &&
              <ModeloEspecializados />
              || Vista == 8 &&
              <VendedorEspecializado />
              || Vista == 9 &&
              <DistribuidorEspecializados />
              || Vista == 10 &&
              <RankingEspecializado />
              || Vista == 11 &&
              <RankingActualEspecializado />
              || Vista == 12 &&
              <RankingVendedor />
              || Vista == 13 &&
              <RankingActualVendedor />
              // || Vista == 14 &&
              // <RankingCampana />
              || Vista == 15 &&
              <RankingCampanaMes />
              || Vista == 16 &&
              <RankingSeccionesMes />
              || Vista == 17 &&
              <RankingSeccionNorte />
              || Vista == 18 &&
              <RankingSeccionCentro />
              || Vista == 19 &&
              <RankingSeccionSur />
              || Vista == 20 &&
              <GanadoresSeccionNorte />
              || Vista == 21 &&
              <GanadoresSeccionCentro />
              || Vista == 22 &&
              <GanadoresSeccionSur />
              || Vista == 23 &&
              <GanadoresRegion />
            }
          </div>
        </div>
      </div>
    </section>
  </>
}
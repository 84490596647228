const ENDPOINT = process.env.REACT_APP_API_URL;

// trarer registros
export async function ComprasAdd(data, token) {
    return fetch(`${ENDPOINT}/register_compra`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'post',
        body: data
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}
// trarer compras
export async function ComprasGet({ IdUser, tokenFull }) {
    return fetch(`${ENDPOINT}/depositosGet/${IdUser}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': tokenFull,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}
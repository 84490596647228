import React from "react";
import Account from "components/Account";
import { Container } from "react-bootstrap";

export default function index() {
  return (
    <Container>
      <Account className="mt-5" />
    </Container>
  );
}

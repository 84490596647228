import React, { useState, useEffect } from 'react';
import useDashboard from '../../hooks/useDashboard';
import useUser from '../../hooks/useUser';
import toast, { Toaster } from 'react-hot-toast';
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import '@progress/kendo-theme-default/dist/all.css';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { filterBy } from "@progress/kendo-data-query";

const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  // {
  //   field: "modelo",
  //   dir: "desc",
  // },
  // {
  //   field: "serie",
  //   dir: "desc",
  // },
  // {
  //   field: "fecha_venta",
  //   dir: "desc",
  // },
  // {
  //   field: "Venta",
  //   dir: "desc",
  // },
];

export default function Ventas() {
  const { success, message, error, DistribuidorEsp, DistribuidorEspecializadosAddService } = useDashboard();
  const { tokenFull, IdUser, Roles } = useUser();
  const [Desde, setDesde] = useState('');
  const [Hasta, setHasta] = useState('');
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);

  const ProductosAgregados = () => {
    toast.loading("Registrando...")
    const data = new FormData();
    data.append("rol", Roles);
    data.append("user_id", IdUser);
    data.append("desde", Desde);
    data.append("hasta", Hasta);

    DistribuidorEspecializadosAddService(data, tokenFull);
  }

  useEffect(() => {
    if (success) {
      toast.dismiss();
      toast.success(message)
    }
  }, [message, success])

  const _export = React.useRef(null);

  const _pdfExport = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const exportPDF = () => {
    if (_pdfExport.current !== null) {
      _pdfExport.current.save();
    }
  };

  const formatNumber = (number) => new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)

  const fecha = (fecha) => {
    let meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    let a = fecha.substr(0, 4);
    let m = fecha.substr(5, 2);
    let d = fecha.substr(8);
    return d + " de " + meses[m - 1] + " de " + a;
  };

  return <>
    <Toaster />
    <h2 className='titulo2'>Distribuidor</h2>
    <div className='row'>
      <div className='col-md-4'>
        <label>Desde</label>
        <input type='date' className='form-control' value={Desde} onChange={e => setDesde(e.target.value)} />
      </div>
      <div className='col-md-4'>
        <label>Hasta</label>
        <input type='date' className='form-control' value={Hasta} onChange={e => setHasta(e.target.value)} />
      </div>
      <div className='col-md-4'>
        <button type='button' className='btn btn-primary' onClick={(() => ProductosAgregados())} style={{ marginTop: 20 }}>Buscar</button>
      </div>
    </div>
    <br />
    <div className="row">
      <div className="col-md-12">
        <GridPDFExport
          fileName="Distribuidor"
          pageTemplate={PageTemplate}
          paperSize="A4"
          margin="1.2cm"
          landscape={true}
          ref={_pdfExport}
        >
          <Grid
            pageable={true}
            data={DistribuidorEsp}
            style={{
              height: "420px",
            }}
          >
            <GridNoRecords>No hay informacion disponible</GridNoRecords>
            <GridColumn field="customer_number" title="No Sap" />
            <GridColumn field="distribuidor" title="Distribuidor" />
            <GridColumn field="cantidad" title="Cantidad" />
            <GridColumn
              field="Venta"
              filter="text"
              format={null}
              cell={(e) => {
                let total = e.dataItem.Venta;
                return (
                  <td style={{ marginTop: 10, textAlign: 'right' }}>{'$' + formatNumber(total)}</td>
                );
              }}
            />
          </Grid>
        </GridPDFExport>
        <ExcelExport
          fileName="Distribuidor"
          data={DistribuidorEsp}
          ref={_export}
        >
          <Grid
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            filterable={true}
            filter={filter}
            onFilterChange={(e) => setFilter(e.filter)}
            pageable={true}
            data={filterBy(DistribuidorEsp, filter)}
            style={{
              height: "420px",
            }}
          >
            <GridToolbar>
              <button
                title="Export Excel"
                className="btn btn-success"
                onClick={excelExport}
              >
                Export to Excel
              </button>
            </GridToolbar>
            <GridNoRecords>No hay informacion disponible</GridNoRecords>
            <GridColumn field="customer_number" title="No Sap" />
            <GridColumn field="distribuidor" title="Distribuidor" />
            <GridColumn field="cantidad" title="Cantidad" />
            <GridColumn
              field="Venta"
              filter="text"
              format={null}
              cell={(e) => {
                let total = e.dataItem.Venta;
                return (
                  <td style={{ marginTop: 10, textAlign: 'right' }}>{'$' + formatNumber(total)}</td>
                );
              }}
            />
          </Grid>
        </ExcelExport>
      </div>
    </div>
  </>
}

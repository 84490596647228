import DepositList from "components/Deposito";
import React from "react";
import { Col, Row } from "reactstrap";

export default function EquivalencePage() {
  return (
    <div className="container">
      <Row className="mt-2">
        <Col>
          <DepositList />
        </Col>
      </Row>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Axios from "axios";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import Messagebox from "./Messagebox";

export default function PublicMessagesPage({ IdUser, setPuntos, setConcentrados }) {
  const [user, setUser] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    Axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

    const echo = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_MIX_ABLY_PUBLIC_KEY,
      wsHost: "realtime-pusher.ably.io",
      wsPort: 443,
      disableStats: true,
      encrypted: true,
    });

    echo
      .channel("ranking.room")
      .subscribed(() => {
        console.log("You are subscribed ranking");
        console.log(IdUser);
      })
      .listen(".ranking.new", (data) => {
        setMessages((oldMessages) => [...oldMessages, data]);
        setMessage("");
        const newdata = [...messages, data];
        if (newdata.length === 1) {
          if (newdata[0].user_id == IdUser) {
            setPuntos(newdata[0].puntos);
            setConcentrados(newdata[0].concentrado);
            window.sessionStorage.setItem("puntos", newdata[0].puntos);
            window.sessionStorage.setItem("concentrado", newdata[0].concentrado);
          }
        }
      });
  }, []);

  return <div></div>;
}

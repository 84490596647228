import React from "react";
import {Row, Col, Button} from "react-bootstrap";

export default function ItemEquivalence({id,modelo,puntos,onDelete,onEdit }) {
  
  return (
    <Row className="fila" key={id}>
      <Col className="columna" sm={6} md={3}> <span > {modelo} </span></Col>      
      <Col className="columna" sm={6} md={3}>
      <span >
            {puntos} puntos
            </span>
      </Col>
      <Col className="columna" sm={6} md={3}>
      <span>
        <Button variant="warning" onClick={() => onEdit(id)}>
          {" "}
          Editar{" "}
        </Button>
        </span>
      </Col>
      <Col className="columna" sm={6} md={3}>
      <span>
        <Button variant="danger" onClick={() => onDelete(id)}>
          {" "}
          Eliminar{" "}
        </Button>
        </span>
      </Col>
    </Row>
  );
}

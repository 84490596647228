const ENDPOINT = process.env.REACT_APP_API_URL;

async function equivalences (token) {
  return await fetch(`${ENDPOINT}/equivalences`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}

async function deleteEquivalence( token, id ) {
  return await fetch(`${ENDPOINT}/equivalences/${id}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    },
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

async function storeEquivalence( token, equivalence) {
  return await fetch(`${ENDPOINT}/equivalences`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equivalence)
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

async function unselect_products (token) {
  return await fetch(`${ENDPOINT}/unselect_products`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}

async function editEquivalence( token, id, data) {
  return await fetch(`${ENDPOINT}/equivalences/${id}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify({ puntos: data })
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}

export default { equivalences, deleteEquivalence, storeEquivalence, unselect_products, editEquivalence } ;

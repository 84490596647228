
const ENDPOINT = process.env.REACT_APP_API_URL;

async function transfers( token, mesActual ) {
  return await fetch(`${ENDPOINT}/transfers/${mesActual}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    },
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}
async function editTransfer( token, id, data) {
  return await fetch(`${ENDPOINT}/transfers/${id}`, {
    method: 'POST',
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: 'Bearer ' + token
    },
    body: data
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });  
}
export { transfers, editTransfer };
import {useCallback, useState} from "react";
import {
  RankingGet,
  RankingAdd,
  RankingAddCampana,
  RankingCampanaGet,
  SeccionGet,
  SeccionesGet,
  GanadorRegionGet,
  RegionGet
} from "../services/ranking";

export default function useCategorias() {
  const [Posicion, setPosicion] = useState([]);
  const [Lugar, setLugar] = useState([]);
  const [Lugares, setLugares] = useState([]);
  const [LugarRegion, setLugarRegion] = useState([]);
  const [PosiconRegion, setPosicionRegion] = useState([]);
  const [Seccion, setSeccion] = useState([]);
  const [Posiciones, setPosiciones] = useState([]);
  const [State, setState] = useState({
    loading: false,
    error: false,
    message: "",
    success: false,
    successs: false,
  });

  const RankingGetService = useCallback((token, Roles) => {
    RankingGet(token, Roles).then((response) => {
      setPosicion(Object.values(response));
    });
  }, []);

  const SeccionGetService = useCallback((token, Dealer) => {
    SeccionGet(token, Dealer).then((response) => {
      setLugar(Object.values(response.data));
      setSeccion(response.seccion)
    });
  }, []);

  const RegionGetService = useCallback((token, Dealer) => {
    RegionGet(token, Dealer).then((response) => {
      setPosicionRegion(Object.values(response.data));
      setSeccion(response.seccion)
    });
  }, []);

  const SeccionesGetService = useCallback((token, Dealer) => {
    SeccionesGet(token, Dealer).then((response) => {
      setLugares(Object.values(response));
    });
  }, []);

  const GanadorRegionGetService = useCallback((token, Dealer) => {
    GanadorRegionGet(token, Dealer).then((response) => {
      setLugarRegion(Object.values(response));
    });
  }, []);

  const RankingAddService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
    });
    RankingAdd(data, token).then((response) => {
      setState({
        loading: false,
        error: false,
        message: "Busqueda realizada con exito",
        success: true,
      });
      setPosicion(response);
    });
  }, []);

  const RankingCampanaGetGetService = useCallback((token) => {
    RankingCampanaGet(token).then((response) => {
      setPosiciones(Object.values(response));
    });
  }, []);

  const RankingAddCampanaService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
    });
    RankingAddCampana(data, token).then((response) => {
      setState({
        loading: false,
        error: false,
        message: "Busqueda realizada con exito",
        success: true,
      });
      setPosicion(response);
    });
  }, []);

  return {
    loading: State.loading,
    error: State.error,
    message: State.message,
    success: State.success,
    successs: State.successs,
    Posicion,
    Posiciones,
    Lugar,
    Lugares,
    Seccion,
    LugarRegion,
    PosiconRegion,
    RankingGetService,
    SeccionGetService,
    SeccionesGetService,
    RegionGetService,
    GanadorRegionGetService,
    RankingAddService,
    RankingAddCampanaService,
    RankingCampanaGetGetService,
  };
}

const ENDPOINT = process.env.REACT_APP_API_URL;

function Register(datos) {
  return fetch(`${ENDPOINT}/auth/register`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: datos,

  }).then((res) => {
    if (res.status === 422) return res.json();
    if (!res.ok) {
      //throw await res.json();
      throw new Error(res.json());
    }
    return res.json()

  }).catch(function (err) {
    console.log(err);
  });
}

async function dealers() {
  return await fetch(`${ENDPOINT}/auth/dealers`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  });
}

async function promotor(datos) {
  return fetch(`${ENDPOINT}/auth/enroll/Promotor`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: datos,

  }).then((res) => {
    if (res.status === 422) return res.json();
    if (!res.ok) {
      //throw await res.json();
      throw new Error(res.json());
    }
    return res.json()

  }).catch(function (err) {
    console.log(err);
  });

}

async function propietario(datos) {
  return fetch(`${ENDPOINT}/auth/enroll/Propietario`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: datos,

  }).then((res) => {
    if (res.status === 422) return res.json();
    if (!res.ok) {
      //throw await res.json();
      throw new Error(res.json());
    }
    return res.json()

  }).catch(function (err) {
    console.log(err);
  });

}
export default { Register, dealers, promotor, propietario };

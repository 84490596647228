const ENDPOINT = process.env.REACT_APP_API_URL;

export async function CategoriasPremiosGet(token) {
    return fetch(`${ENDPOINT}/CategoriasPremiosGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

// trarer premios
export async function PremiosGet(token) {
    return fetch(`${ENDPOINT}/premiosGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function PremiosAdd(data, token) {
    return fetch(`${ENDPOINT}/premiosAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'post',
        body: data
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function PremiosUpdate(data, token) {
    return fetch(`${ENDPOINT}/premiosUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'post',
        body: data
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function PremiosDelete(data, token) {
    return fetch(`${ENDPOINT}/premiosDelete`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'post',
        body: data
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}
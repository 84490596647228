const ENDPOINT = process.env.REACT_APP_API_URL;

// trarer registros
export async function VentasAdd(data, token) {
  return fetch(`${ENDPOINT}/ventasAdd`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function VentasEspecializadosAdd(data, token) {
  return fetch(`${ENDPOINT}/ventasEspecializadoAdd`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

// trarer registros
export async function ModeloAdd(data, token) {
  return fetch(`${ENDPOINT}/modeloAdd`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ModeloEspecializadosAdd(data, token) {
  return fetch(`${ENDPOINT}/modeloEspecializadoAdd`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

// trarer registros
export async function VendedorAdd(data, token) {
  return fetch(`${ENDPOINT}/vendedorAdd`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function VendedorEspecializadosAdd(data, token) {
  return fetch(`${ENDPOINT}/vendedorEspecializadoAdd`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}
// trarer registros
export async function DistribuidorAdd(data, token) {
  return fetch(`${ENDPOINT}/distribuidorAdd`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function DistribuidorEspecializadosAdd(data, token) {
  return fetch(`${ENDPOINT}/distribuidorEspecializadoAdd`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token,
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

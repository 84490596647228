const ENDPOINT = process.env.REACT_APP_API_URL;

export default function statesService () {
  return fetch(`${ENDPOINT}/auth/states`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    }
  }).then(res => {
    if (!res.ok) throw new Error('Response is NOT ok')
    return res.json()
  })
}

//export default { statesService } ;
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import useUser from "../../hooks/useUser";
import useRanking from "../../hooks/useRanking";
import "./dashboard_vendedor.css";
import usuario from "../../assets/images/Usuario.png";
import Swal from "sweetalert2";
import Escolaridad from "components/Escolaridad";
import VentasPromotor from "components/Dashboard/VentasPromotor";

export default function Dashboard() {
  const { tokenFull, IdUser, Puntos, Roles, UserNombre, Foto, Concentrados } =
    useUser();
  const { error, loading, message, Posicion, RankingGetService } = useRanking();
  const [Vista, setVista] = useState(0);
  const navigate = useNavigate();
  const [CurrentPagina, setCurrentPagina] = useState(1);
  const [Pagina, setPagina] = useState(0);

  const goToRoute = (ruta) => {
    navigate("/" + ruta);
  };

  const ENDPOINT = process.env.REACT_APP_BASE_URL;

  return (
    <>
      <div className="login__background">
        <div className="container fondo_blanco">
          <div className="row">
            <div className="col-md-6">
              <h2>
                {Foto == null || Foto == "null" ? (
                  <img width={65} src={usuario} className="image_usuario" />
                ) : (
                  <img
                    width={65}
                    src={ENDPOINT + "/images/foto/" + Foto}
                    className="image_usuario rounded-circle"
                  />
                )}

                {UserNombre}
              </h2>
            </div>
            <div className="col-md-6 text-end">
            
            </div>
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12" >

              <button
                type="button"
                className="btn btn-dark"
                style={{ marginBottom: 20 }}
                 onClick={() => goToRoute("registrar-producto")}
              >
               Registra tu venta
              </button>
              <button
                type="button"
                className="btn btn-dark"
                style={{ marginBottom: 20 }}
                onClick={() => goToRoute("editar-cuenta")}
              >
                Editar mis datos
              </button>
            </div>
          </div>

          <div className="row" >
            <div className="col-md-12" style={{padding: '25px'}} >
              <VentasPromotor />
            </div>
          </div>




          <Escolaridad />
        </div>
      </div>
    </>
  );
}

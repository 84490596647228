import { useCallback, useState } from "react";
import {
  CuentaGet,
  CuentaUpdate,
  DistribuidorGet,
  EstadoGet,
} from "../services/editarAccount";

export default function useCategorias() {
  const [Imagen, setImagen] = useState("");
  const [Nombre, setNombre] = useState("");
  const [ApellidoP, setApellidoP] = useState("");
  const [ApellidoM, setApellidoM] = useState("");
  const [Celular, setCelular] = useState("");
  const [Email, setEmail] = useState("");
  const [Genero, setGenero] = useState("");
  const [Tipo, setTipo] = useState("");
  const [Fecha, setFecha] = useState("");
  const [Distribuidor, setDistribuidor] = useState("");
  const [Calle, setCalle] = useState("");
  const [Exterior, setExterior] = useState("");
  const [Interior, setInterior] = useState("");
  const [Cp, setCp] = useState("");
  const [Colonia, setColonia] = useState("");
  const [Ciudad, setCiudad] = useState("");
  const [Estado, setEstado] = useState("");

  const [Dealer, setDealer] = useState([]);
  const [States, setStates] = useState([]);

  const [State, setState] = useState({
    loading: false,
    error: false,
    message: "",
    success: false,
  });

  const DistribuidorGetService = useCallback(({ tokenFull }) => {
    DistribuidorGet({ tokenFull }).then((response) => {
      setDealer(response);
    });
  }, []);

  const EstadoGetService = useCallback(({ tokenFull }) => {
    EstadoGet({ tokenFull }).then((response) => {
      setStates(response);
    });
  }, []);

  const CuentaGetService = useCallback(({ IdUser, tokenFull }) => {
    CuentaGet({ IdUser, tokenFull }).then((response) => {
      setImagen(response.foto);
      setNombre(response.nombre);
      setApellidoP(response.apellidoP);
      setApellidoM(response.apellidoM);
      setCelular(response.celular);
      setFecha(response.cumpleanos);
      setEmail(response.email);
      setGenero(response.genero);
      setTipo(response.progenitor);
      setImagen(response.foto);

      setDistribuidor(response.distribuidor);

      setCalle(response.calle);
      setExterior(response.exterior);
      setInterior(response.interior);
      setCp(response.cp);
      setColonia(response.colonia);
      setCiudad(response.ciudad);
      setEstado(response.estado);
    });
  }, []);

  const CuentaUpdateService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
    });
    CuentaUpdate(data, token).then((response) => {
      let errores = response.errors;
      if (errores !== undefined) {
        let nombre = errores['nombre'];
        let apellidoP = errores['apellidoP'];
        let apellidoM = errores['apellidoM'];
        let celular = errores['celular'];
        let genero = errores['genero'];
        let tipo = errores['tipo'];
        let fecha = errores['fecha'];
        let distribuidor = errores['distribuidor'];
        let calle = errores['calle'];
        let exterior = errores['exterior'];
        let cp = errores['cp'];
        let colonia = errores['colonia'];
        let ciudad = errores['ciudad'];
        let estado = errores['estado'];

        if (nombre != null) { setState({ loading: false, error: true, message: nombre[0], success: false }) }
        if (apellidoP != null) { setState({ loading: false, error: true, message: apellidoP[0], success: false }) }
        if (apellidoM != null) { setState({ loading: false, error: true, message: apellidoM[0], success: false }) }
        if (celular != null) { setState({ loading: false, error: true, message: celular[0], success: false }) }
        if (genero != null) { setState({ loading: false, error: true, message: genero[0], success: false }) }
        if (tipo != null) { setState({ loading: false, error: true, message: tipo[0], success: false }) }
        if (fecha != null) { setState({ loading: false, error: true, message: fecha[0], success: false }) }
        if (distribuidor != null) { setState({ loading: false, error: true, message: distribuidor[0], success: false }) }
        if (calle != null) { setState({ loading: false, error: true, message: calle[0], success: false }) }
        if (exterior != null) { setState({ loading: false, error: true, message: exterior[0], success: false }) }
        if (cp != null) { setState({ loading: false, error: true, message: cp[0], success: false }) }
        if (colonia != null) { setState({ loading: false, error: true, message: colonia[0], success: false }) }
        if (ciudad != null) { setState({ loading: false, error: true, message: ciudad[0], success: false }) }
        if (estado != null) { setState({ loading: false, error: true, message: estado[0], success: false }) }

      } else {
        if (response.guardado === true) {
          setState({
            loading: false,
            error: false,
            message: "Cuenta actualizada con exito",
            success: true,
          });
        } else {
          setState({
            loading: false,
            error: true,
            message: "Error al actualizar cuenta",
            success: false,
          });
        }
      }
    });
  }, []);

  return {
    loading: State.loading,
    error: State.error,
    message: State.message,
    success: State.success,
    Imagen,
    Nombre,
    setNombre,
    ApellidoP,
    setApellidoP,
    ApellidoM,
    setApellidoM,
    Celular,
    setCelular,
    Email,
    Genero,
    setGenero,
    Tipo,
    setTipo,
    Fecha,
    setFecha,
    Distribuidor,
    setDistribuidor,
    Calle,
    setCalle,
    Exterior,
    setExterior,
    Interior,
    setInterior,
    Cp,
    setCp,
    Colonia,
    setColonia,
    Ciudad,
    setCiudad,
    Estado,
    setEstado,

    Dealer,
    States,

    CuentaGetService,
    CuentaUpdateService,
    DistribuidorGetService,
    EstadoGetService,
  };
}

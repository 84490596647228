import React, { useEffect, useState } from "react";
import csrfService from "services/csrf";
import promotorService from "services/register";
import statesService from "services/states";
import { ModalForm } from "components/ModalForm";
import { useForm, Controller } from "react-hook-form";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "react-datepicker/dist/react-datepicker.css";
import {  subYears, format } from "date-fns";
import { es } from "date-fns/locale";
import "../../utilities/inputs.css";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import avatar from "assets/images/Usuario.png";
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const FILE_SIZE = 8000000;
const obligatorio = "El campo es obligatorio.";
const schema = yup.object().shape({
  foto: yup
    .mixed()
    .nullable()
    .notRequired()
    .test(
      "fileSize",
      "El archivo es muy pesado. Tamaño maximo: 8 MB.",
      (file) => {
        if (file.length > 0) {
          return file[0].size <= FILE_SIZE;
        } else {
          return true;
        }
      }
    )
    .test(
      "fileFormat",
      "Formato incorrecto. Favor de intentar con los siguientes formatos: png,jpg o jpeg",
      (file) => {
        if (file.length > 0) {
          return SUPPORTED_FORMATS.includes(file[0]?.type);
        } else {
          return true;
        }
      }
    ),

  nombre: yup.string().required(obligatorio),
  apellido_paterno: yup.string().required(obligatorio),
  apellido_materno: yup.string().required(obligatorio),
  celular: yup
    .string()
    .trim()
    .matches(/^\d{10}$/, "Escribir solo números. 10 dígitos.")
    .required(obligatorio),
  email: yup
    .string()
    .email("Debes escribir un correo electrónico.")
    .required(obligatorio),
  password: yup
    .string()
    .test(
      "len",
      "El campo debe ser mayor o igual a 8 caracteres.",
      (val) => val.length >= 8
    ),
  genero: yup.string().required(obligatorio),
  progenitor: yup.string().required(obligatorio),
  cumpleanos: yup.date().required(obligatorio),
  escolaridad: yup.string().required(obligatorio),
  dealer_id: yup.object().shape({
    label: yup.string().required(obligatorio),
    value: yup.string().required(obligatorio),
  }),
  calle: yup.string().required(obligatorio),
  numero_exterior: yup.string().required(obligatorio),
  codigo_postal: yup.string().required(obligatorio),
  colonia: yup.string().required(obligatorio),
  ciudad: yup.string().required(obligatorio),
  state_id: yup.object().shape({
    label: yup.string().required(obligatorio),
    value: yup.string().required(obligatorio),
  }),
  publicidad: yup
    .bool()
    .oneOf([true], "Necesitas aceptar el envío de publicidad."),
  terminos: yup
    .bool()
    .oneOf([true], "Necesitas aceptar los términos y condiciones"),
});

const animatedComponents = makeAnimated();

export default function Register() {
  const {
    control,
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [registered, setRegistered] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [states, setStates] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [image, setImage] = useState("");

  const defaultYear = subYears(new Date(), 25).getFullYear();
  const [selectedDate, setSelectedDate] = useState(new Date(defaultYear, 0, 1));

  useEffect(() => {
    statesService().then((data) => {
      setStates(data);
    });
    promotorService.dealers().then((data) => {
      setDealers(data);
    });
  }, []);

  useEffect(() => {
    if (registered) {
      reset();
    }
  }, [registered]);

  const onSubmit = (values) => {
    if (values.cumpleanos) {
      values.cumpleanos = format(values.cumpleanos, "yyyy/MM/dd");
    }
    if (values.state_id) {
      values.state_id = values.state_id.value;
    }
    if (values.dealer_id) {
      console.log(values.dealer_id);
      values.dealer_id = values.dealer_id.value;
    }
    if (values.foto) {
      values.foto = values.foto[0];
    }
    if (values.foto === undefined) {
      values.foto = "";
    }

   let campos = {
      nombre_domicilio: "Mi domicilio"
    };

    const payload = { ...values, ...campos };

    const formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }

    setIsSubmitting(true);
    csrfService().then(() => {
      promotorService
        .promotor(formData)
        .then((response) => {
          if (response.hasOwnProperty("errors")) {
            setRegistered(false);
            let msj = "Errores: \n";
            for (const [key, value] of Object.entries(response.errors)) {
              msj += `${key}: ${value} \n`;
            }
            alert(msj);
          } else {
            setRegistered(true);
          }
        })
        .catch(function (error) {
          console.log("Catch:" + error.message);
        });
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div>
      <Container fluid="xxl" className="registro">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Row className="justify-content-center mt-5">
            <Col xs={12} md={4}>
              <Form.Group controlId="formFile" className="mb-3">
                <div className="text-center">
                  <img
                    src={avatar}
                    className="img-fluid profile"
                    alt="Avatar"
                  />
                </div>
                <Form.Label>Foto de perfil</Form.Label>

                <Form.Control
                  type="file"
                  {...register("foto", {
                    required: {
                      value: true,
                      message: "La imagen es obligatoria.",
                    },
                    validate: {
                      lessThan10MB: (files) =>
                        files[0]?.size < 10000000 || "Max 10MB",
                      acceptedFormats: (files) =>
                        ["image/jpeg", "image/png", "image/gif"].includes(
                          files[0]?.type
                        ) || "Only PNG, JPEG e GIF",
                    },
                  })}
                />

                <Form.Text className="text-danger">
                  {errors.foto?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-start mx-1">
            <Col xs={12} md={4}>
              <span className="text-danger">*</span> Campos obligatorios
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="nombre">
                <Form.Label>
                  Nombre <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre(s)"
                  {...register("nombre", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-danger">
                  {errors.nombre?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="apellido_paterno">
                <Form.Label>
                  Apellido Paterno <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("apellido_paterno", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-danger">
                  {errors.apellido_paterno?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="apellido_materno">
                <Form.Label>
                  Apellido Materno <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control type="text" {...register("apellido_materno")} />
                <Form.Text className="text-danger">
                  {errors.apellido_materno?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mt-2">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="celular">
                <Form.Label>
                  Celular <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  placeholder="10 dígitos"
                  type="text"
                  maxLength={10}
                  {...register("celular")}
                />
                <Form.Text className="text-danger">
                  {errors.celular?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  {...register("email", {
                    required: "El campo es obligatorio.",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Debe escribir un correo.",
                    },
                  })}
                />
                <Form.Text className="text-danger">
                  {errors.email?.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>
                  Contraseña <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("password", {
                    required: "El campo es obligatorio.",
                    minLength: {
                      value: 8,
                      message:
                        "La contraseña debe tener como mínimo 8 caracteres.",
                    },
                  })}
                />
                <Form.Text className="text-danger">
                  {errors.password?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mt-2">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="genero">
                <Form.Label>
                  Género <span className="text-danger">*</span>
                </Form.Label>

                <Form.Select
                  aria-label="genero"
                  {...register("genero", {
                    required: "El campo es obligatorio.",
                  })}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
                  <option value="Otro">Otro</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.genero?.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="progenitor">
                <Form.Label>
                  Soy Padre/Madre de familia{" "}
                  <span className="text-danger">*</span>
                </Form.Label>

                <Form.Select
                  aria-label="progenitor"
                  {...register("progenitor", {
                    required: "El campo es obligatorio.",
                  })}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="Si">Sí</option>
                  <option value="No">No</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.progenitor?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="cumpleanos">
                <Form.Label>
                  Fecha de Nacimiento <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name="cumpleanos"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      inputRef={ref}
                      type="text"
                      autoComplete="off"
                      as={ReactDatePicker}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={subYears(new Date('2023-12-31'), 14)}
                      locale={es}
                      placeholderText="Selecciona la fecha"
                      dateFormat="dd-MM-yyyy"
                      error={!!errors.cumpleanos}
                    />
                  )}
                />

                <Form.Text className="text-danger">
                  {errors.cumpleanos?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-start mt-2">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="escolaridad">
                <Form.Label>
                  Escolaridad <span className="text-danger">*</span>
                </Form.Label>

                <Form.Select
                  aria-label="escolaridad"
                  {...register("escolaridad", {
                    required: "El campo es obligatorio.",
                  })}
                >
                  <option value="">Selecciona una opción</option>
                  <option value="Sin escolaridad">Sin escolaridad</option>
                  <option value="Primaria">Primaria</option>
                  <option value="Secundaria">Secundaria</option>
                  <option value="Preparatorio / Bachillerato">
                    Preparatorio / Bachillerato
                  </option>
                  <option value="Carrera técnica">Carrera técnica</option>
                  <option value="Licenciatura">Licenciatura</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.escolaridad?.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="dealer_id">
                <Form.Label>
                  Distribuidor <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  rules={{ required: "El campo es obligatorio" }}
                  control={control}
                  name="dealer_id"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={dealers}
                      inputRef={ref}
                      as={Select}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <Form.Text className="text-danger">
                  {errors.dealer_id?.label?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <p className="mt-4 pt-2">
                Si no conoces el número de distribuidor, contáctanos al Whatsapp{" "}
                <a
                  target="blank"
                  href="https://api.whatsapp.com/send?phone=5212211048168"
                >
                  221 104 8168
                </a>
                . ¡Nosotros te ayudamos!
              </p>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <h6>DIRECCIÓN PERSONAL</h6>
            </Col>
          </Row>
          <Row className="justify-content-start mt-3">
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="calle">
                <Form.Label>
                  Calle <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre de la calle"
                  {...register("calle", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-danger">
                  {errors.calle?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={2}>
              <Form.Group className="mb-3" controlId="numero_exterior">
                <Form.Label>
                  Número exterior <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Núm. exterior"
                  {...register("numero_exterior", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-danger">
                  {errors.numero_exterior?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={2}>
              <Form.Group className="mb-3" controlId="numero_interior">
                <Form.Label>Número interior</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Núm. interior"
                  {...register("numero_interior")}
                />
                <Form.Text className="text-danger">
                  {errors.numero_interior?.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={12} md={2}>
              <Form.Group className="mb-3" controlId="codigo_postal">
                <Form.Label>
                  Código postal <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="5"
                  placeholder="Código postal"
                  {...register("codigo_postal", {
                    required: "El campo es obligatorio.",
                  })}
                />
                <Form.Text className="text-danger">
                  {errors.codigo_postal?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="colonia">
                <Form.Label>
                  Colonia <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Colonia"
                  {...register("colonia")}
                />
                <Form.Text className="text-danger">
                  {errors.colonia?.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group className="mb-3" controlId="ciudad">
                <Form.Label>
                  Ciudad <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ciudad"
                  {...register("ciudad")}
                />
                <Form.Text className="text-danger">
                  {errors.ciudad?.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col xs={12} md={3}>
              <Form.Group className="mb-3" controlId="estado">
                <Form.Label>
                  Estado <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="state_id"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={states}
                      inputRef={ref}
                      as={Select}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                <Form.Text className="text-danger">
                  {errors.state_id?.label?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>


          <Row className="justify-content-start mt-5">
            <Col xs={12} md={8}>
              <Form.Group className="mb-1" controlId="publicidad">
                <span className="text-danger">*</span>
                <Form.Check
                  className="d-inline-block me-2 ms-2"
                  type="checkbox"
                  id={`publicidad`}
                  label={
                    "Acepto recibir información de promociones y/o publicidad de parte de Puntos Naranjas STIHL"
                  }
                  {...register("publicidad")}
                />
                <Form.Text className="text-danger">
                  {errors.publicidad?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-start mt-0">
            <Col xs={12} md={8}>
              <Form.Group className="mb-1" controlId="terminos">
                <span className="text-danger d-inline">*</span>
                <Form.Check
                  className="d-inline-block me-2 ms-2"
                  type="checkbox"
                  id={`terminos`}
                  label={
                    "Acepto los términos y condiciones de Puntos Naranjas STIHL."
                  }
                  {...register("terminos")}
                />
                <a
                  target="blank"
                  href="https://contactostihl.com/PuntosNaranjas.pdf"
                >
                  Ver términos y condiciones
                </a>
                <Form.Text className="text-danger d-block">
                  {errors.terminos?.message}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Button type="submit" className="mb-4 mt-5">
            Enviar
          </Button>
        </Form>

        <Col>
          {registered && (
            <ModalForm
              show={registered}
              title="Notificación"
              handleModal={setRegistered}
            >
              <h4>
                ¡Felicidades has terminado el registro! Solo tienes que esperar
                a que tu cuenta sea activada. Recibirás un correo electrónico
                cuando esto suceda.
              </h4>
            </ModalForm>
          )}
        </Col>
      </Container>
    </div>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";

function ModalForm({ show, title, children, handleModal }) {  
  return ReactDOM.createPortal(
    <Modal
        show={show}
        onHide={()=>handleModal(false)}
        backdrop="static"
        keyboard={false}
        size="md"
        style={{zIndex:'99999999'}}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={()=>handleModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary">Guardar</Button>
        </Modal.Footer> */}
      </Modal>,
    document.getElementById("modal")
  );
}

export { ModalForm };

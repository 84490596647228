import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import useUser from '../../hooks/useUser';
import useCategorias from '../../hooks/useCategorias';
import { Update } from '@mui/icons-material';

export default function ModalFactura({
    modal,
    toggle,
    Id,
    Update,
    Nombre,
    setId,
    setNombre,
    CategoriasGetService }) {

    const { tokenFull, IdUser, Puntos } = useUser();
    const { success, error, message, CategoriasAddService, CategoriasUpdateService } = useCategorias();

    useEffect(() => {
        if (success == true) {
            CategoriasGetService(tokenFull)
        }
    }, [CategoriasGetService, success])

    // useEffect(() => {
    //     if (error) toast.error(message)
    // }, [message, error])

    // useEffect(() => {
    //     if (success) toast.success(message)
    // }, [message, success])

    const categoriasAgregados = () => {
        if (Update) {
            const data = new FormData();
            data.append("id", Id);
            data.append("nombre", Nombre);

            CategoriasUpdateService(data, tokenFull);
            toggle();
        } else {
            const data = new FormData();
            data.append("nombre", Nombre);

            CategoriasAddService(data, tokenFull);
            toggle();
        }
    }

    return <>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {
                    Update
                    ?
                    'Actualizar Categoria'
                    :
                    'Agregar Categoria'
                }
                
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12">
                        <InputGroup>
                            <Input placeholder="nombre" value={Nombre} onChange={e => setNombre(e.target.value)} />
                        </InputGroup>
                    </div>
                </div>
                <br />
            </ModalBody>
            <ModalFooter>
                <Button className="backend_boton" onClick={(() => categoriasAgregados())}>Agregar</Button>{' '}
            </ModalFooter>
        </Modal>
    </>
}

import React, { useEffect, useContext, useState } from "react";
import Context from "context/UserContext";
import api from "services/equivalences";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useForm, Controller } from "react-hook-form";

import { Container, Form, Row, Col, Button } from "react-bootstrap";

const animatedComponents = makeAnimated();

export default function EquivalenceForm({ show, get_equivalences }) {
  const [products, setProducts] = useState([]);
  const [registered, setRegistered] = useState(false);

  const { token } = useContext(Context);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    get_products();
  }, []);

  const get_products = () => {
    api.unselect_products(token).then((data) => {
      setProducts(data);
    });
  };

  const onSubmit = ({ productos, puntos }) => {
    const products_points = productos.map((item) => {
      return { producto_id: item.value, puntos: puntos };
    });

    api
      .storeEquivalence(token, { productos: products_points, puntos })
      .then((response) => {
        if (response.hasOwnProperty("errors")) {
          setRegistered(false);
          alert("Favor de revisar el formulario.");
        } else {
          setRegistered(true);
          show(false);
          get_equivalences();
        }
      });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Row>
          <Col>
          <p>
          Selecciona una colección de producto y asígnale un valor en puntos
          </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="productos">
              <Form.Label>Productos</Form.Label>
              <Controller
                rules={{ required: true }}
                control={control}
                name="productos"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={products}
                    inputRef={ref}
                    as={Select}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                  />
                )}
              />

              <Form.Text className="text-muted">
                {/*   FIX display error */}
                {errors.productos?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="puntos">
              <Form.Label>Puntos</Form.Label>
              <Form.Control
                type="text"
                placeholder="puntos"
                {...register("puntos", {
                  required: "El campo es obligatorio.",
                })}
              />
              <Form.Text className="text-muted">
                {errors.puntos?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" className="mb-2">
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

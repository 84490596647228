import React, { useState, useEffect, useRef } from 'react'

import useCuenta from '../../hooks/useCuenta';
import useUser from '../../hooks/useUser';
import avatar from "../../assets/images/Usuario.png";
import Swal from "sweetalert2";
import Select from 'react-select';
import { Call } from '@mui/icons-material';

const Sexo = [
    { value: "Masculino", label: "Masculino" },
    { value: "Femenino", label: "Femenino" },
];

const Progenitor = [
    { value: "Si", label: "Si" },
    { value: "No", label: "No" },
];

export default function HomePage() {
    const { success, message, error,
        Imagen, Nombre, setNombre, ApellidoP, setApellidoP, ApellidoM, setApellidoM,
        Celular, setCelular, Email, Genero, setGenero, Tipo, setTipo,
        Distribuidor, setDistribuidor,
        Fecha, setFecha, Calle, setCalle, Exterior, setExterior,
        Interior, setInterior, Cp, setCp, Colonia, setColonia, Ciudad, setCiudad,
        Estado, setEstado, Dealer, States,
        CuentaGetService, CuentaUpdateService, DistribuidorGetService, EstadoGetService } = useCuenta();
    const { tokenFull, IdUser, Puntos } = useUser();

    const [SelectedGenero, setSelectedGenero] = useState([]);
    const [SelectedProgenitor, setSelectedProgenitor] = useState([]);
    const [SelectedDistribuidor, setSelectedDistribuidor] = useState([]);
    const [SelectedEstado, setSelectedEstado] = useState([]);
    const [selectedFile, setselectedFile] = useState('')
    const [isFilePicked, setisFilePicked] = useState(false)


    useEffect(() => {
        CuentaGetService({ IdUser, tokenFull })
    }, [CuentaGetService])

    useEffect(() => {
        DistribuidorGetService({ tokenFull })
    }, [DistribuidorGetService])

    useEffect(() => {
        EstadoGetService({ tokenFull })
    }, [EstadoGetService])

    const actualizarCuenta = () => {
        const data = new FormData();
        data.append("id", IdUser);
        data.append("nombre", Nombre);
        data.append("apellidoP", ApellidoP);
        data.append("apellidoM", ApellidoM);
        data.append("celular", Celular);
        data.append("genero", Genero);
        data.append("tipo", Tipo);
        data.append("fecha", Fecha);
        data.append("distribuidor", Distribuidor);
        data.append("calle", Calle);
        data.append("exterior", Exterior);
        data.append("interior", Interior);
        data.append("cp", Cp);
        data.append("colonia", Colonia);
        data.append("ciudad", Ciudad);
        data.append("estado", Estado);
        data.append("imagen", selectedFile);

        CuentaUpdateService(data, tokenFull);

        Swal.fire({
            html: "Espere por favor...",
            timer: 2000,
            confirmButtonColor: "#3b82f6",
            timerProgressBar: true,
        });
    }

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
        }
    }, [message, error])

    useEffect(() => {
        if (success) {
            Swal.fire({
                icon: "success",
                title: "Exito",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
            setselectedFile('');
        }
    }, [message, success])

    const changeHandler = (event) => {
        setselectedFile(event.target.files[0]);
        setisFilePicked(true);
    };


    const ENDPOINT = process.env.REACT_APP_BASE_URL;

    return <>
        <div className='container' style={{ paddingTop: 50, paddingBottom: 50 }}>
            <div className='row'>
                <div className='col-md-4 text-center mx-auto'>
                    {
                        Imagen == null
                            ?
                            <img src={avatar} className="img-fluid mx-auto d-block" />
                            :
                            <img src={ENDPOINT + '/images/foto/' + Imagen} style={{width: 140}} className="img-fluid mx-auto d-block rounded-circle" />
                    }

                    <label style={{ marginTop: 10, marginBottom: 10 }}>Foto de perfil</label>
                    <input type='file' className='form-control' onChange={changeHandler} />
                </div>
            </div>
            <br /><br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>Nombre</label>
                    <input type='text' className='form-control' value={Nombre} onChange={e => setNombre(e.target.value)} />
                </div>
                <div className='col-md-4 text-center'>
                    <label>Apellido Paterno</label>
                    <input type='text' className='form-control' value={ApellidoP} onChange={e => setApellidoP(e.target.value)} />
                </div>
                <div className='col-md-4 text-center'>
                    <label>Apellido Materno</label>
                    <input type='text' className='form-control' value={ApellidoM} onChange={e => setApellidoM(e.target.value)} />
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>Celular</label>
                    <input type='text' className='form-control' value={Celular} onChange={e => setCelular(e.target.value)} />
                </div>
                <div className='col-md-4 text-center'>
                    <label>Email</label>
                    <input type='text' className='form-control' disabled={true} value={Email} />
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>Género</label>
                    <Select
                        value={SelectedGenero}
                        onChange={(e) => {
                            setGenero(e.value);
                            setSelectedGenero(e)
                        }}
                        options={Sexo}
                        placeholder={Genero ? Genero : "Género"}
                    />
                </div>
                <div className='col-md-4 text-center'>
                    <label>Soy Padre/Madre de familia</label>
                    <Select
                        value={SelectedProgenitor}
                        onChange={(e) => {
                            setTipo(e.value);
                            setSelectedProgenitor(e)
                        }}
                        options={Progenitor}
                        placeholder={Tipo ? Tipo : "Soy Padre/Madre de familia"}
                    />
                </div>
                <div className='col-md-4 text-center'>
                    <label>Cumpleaños</label>
                    <input type='date' className='form-control' value={Fecha} onChange={e => setFecha(e.target.value)} />
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>Distribuidor</label>
                    <Select
                        value={SelectedDistribuidor}
                        onChange={(e) => {
                            setDistribuidor(e.value);
                            setSelectedDistribuidor(e)
                        }}
                        options={Dealer}
                        placeholder={Distribuidor ? Distribuidor : "Distribuidor"}
                    />
                </div>
                <div className='col-md-4 text-center'>
                    <p className="mt-4 pt-2">
                        Si no conoces el número de distribuidor, contáctanos al Whatsapp{" "}
                        <a
                            target="blank"
                            href="https://api.whatsapp.com/send?phone=5212225809898"
                        >
                            2225809898
                        </a>
                        . ¡Nosotros te ayudamos!
                    </p>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4'>
                    <h6>DIRECCIÓN PERSONAL</h6>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-5 text-center'>
                    <label>Calle</label>
                    <input type='text' className='form-control' value={Calle} onChange={e => setCalle(e.target.value)} />
                </div>
                <div className='col-md-2 text-center'>
                    <label>Número exterior</label>
                    <input type='text' className='form-control' value={Exterior} onChange={e => setExterior(e.target.value)} />
                </div>
                <div className='col-md-2 text-center'>
                    <label>Número interior</label>
                    <input type='text' className='form-control' value={Interior} onChange={e => setInterior(e.target.value)} />
                </div>
                <div className='col-md-3 text-center'>
                    <label>Código postal</label>
                    <input type='text' className='form-control' value={Cp} onChange={e => setCp(e.target.value)} />
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>Colonia</label>
                    <input type='text' className='form-control' value={Colonia} onChange={e => setColonia(e.target.value)} />
                </div>
                <div className='col-md-4 text-center'>
                    <label>Ciudad</label>
                    <input type='text' className='form-control' value={Ciudad} onChange={e => setCiudad(e.target.value)} />
                </div>
                <div className='col-md-4 text-center'>
                    <label>Estado</label>
                    <Select
                        value={SelectedEstado}
                        onChange={(e) => {
                            setEstado(e.label);
                            setSelectedEstado(e)
                        }}
                        options={States}
                        placeholder={Estado ? Estado : "Estado"}
                    />
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4'>
                    <button type='button' className='btn btn-primary' onClick={(() => actualizarCuenta())}>Actualizar</button>
                </div>
            </div>
        </div>
    </>
}